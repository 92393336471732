.product-header-module {
    z-index: 900;
    position: absolute;
    background: $color-grenke-grey-warm;
    opacity: 0.9;
    height: 26px;
    left: 0;
    top: 0;
    a {
        color: $color-grenke-grey-dark;
        text-decoration: underline;
    }
}