#contact-detail-page {
  .contact-detail-form-interest {
    &-wrapper {
      .contact-detail-form-interest-row + .contact-detail-form-interest-row {
        margin-top: 20px;
      }
    }
  }

  #js-warning {
    margin-top: $bottom-gap-module-xs;
    @include media-breakpoint-up(sm) {
      margin-top: $bottom-gap-module-sm;
    }
    @include media-breakpoint-up(md) {
      margin-top: $bottom-gap-module-md;
    }
    @include media-breakpoint-up(lg) {
      margin-top: $bottom-gap-module-lg;
    }
    @include media-breakpoint-up(xl) {
      margin-top: $bottom-gap-module-xl;
    }
  }

  #contact-detail-form {
    @include std-bottom-margin(false);

    .css-required {
      &:after {
        display: inline;
        content: "*";
      }
    }

    h3 + .formRow {
      margin-top: 20px;
    }

    h4 + .formRow {
      margin-top: 20px;
    }

    button[type="submit"] {
      cursor: pointer;

      &:disabled,
      &[disabled] {
        .btn {
          cursor: wait;

          &:hover {
            cursor: wait;
          }
        }
      }
    }

    @include media-breakpoint-only(xs) {
      .formRow--col-float + .formRow--col-float {
        margin-top: 20px;
      }
    }

    .phone-contact {
      &-wrapper {
        background-color: $color-grenke-grey-warm;
        padding: 25px 20px 25px 65px;
        position: relative;
        right: -15px;
        z-index: 1;
        @include media-breakpoint-up(sm) {
          right: auto;
        }
        @include media-breakpoint-only(sm) {
          padding: 25px 0px 25px 75px;
          &:before {
            content: "";
            position: absolute;
            background-color: $color-grenke-grey-warm;
            height: 100%;
            width: 50vw;
            top: 0;
            left: 0;
          }
        }
        @include media-breakpoint-only(md) {
          padding: 30px 30px 30px 90px;
        }
        @include media-breakpoint-only(lg) {
          padding: 30px 30px 30px 90px;
        }
        @include media-breakpoint-only(xl) {
          padding: 30px 30px 30px 100px;
        }
      }

      &-icon,
      &-text,
      &-number {
        position: relative;
        z-index: 1;
      }

      &-icon {
        background-image: url(../website/medias/icons/contactform_speech_bubble.png);
        background-size: 35px;
        background-position: center;
        width: 35px;
        height: 35px;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        @include media-breakpoint-up(sm) {
          background-size: 40px;
          width: 40px;
          height: 40px;
          left: 25px;
        }
        @include media-breakpoint-only(md) {
          background-size: 40px;
          width: 40px;
          height: 40px;
          left: 30px;
        }
        @include media-breakpoint-only(lg) {
          background-size: 40px;
          width: 40px;
          height: 40px;
          left: 30px;
        }
        @include media-breakpoint-only(xl) {
          background-size: 50px;
          width: 50px;
          height: 50px;
          left: 30px;
        }
      }

      &-text,
      &-number {
        text-align: center;
        color: $color-grenke-blue;
      }

      &-text {
        &-item {
          @include tiles-module-copy;
        }
      }

      &-number {
        &-item {
          @include copy-3;
          & {
            color: $color-grenke-blue;
            text-decoration: underline;
          }

          &:hover {
            color: $color-grenke-blue;
            text-decoration: underline;
          }
        }
      }
    }

    &[data-form-interest="Career"] {
      [data-form-input-name="Country"] {
        margin-top: 0;
      }

      [data-form-input-name="Address"] {
        @include media-breakpoint-up(sm) {
          margin-top: 0;
        }
      }
    }

    [data-form-input-name="PostalCode"] {
      @include media-breakpoint-only(xs) {
        margin-top: 20px;
      }
    }
  }

  .upload {
    &-row {
      position: relative;

      .upload-error {
        display: none;

        &.file-type,
        &.file-size {
          position: relative;
          @include formfield-error;
          color: $color-grenke-red;
          bottom: -3px;
          left: 17px;
          @include media-breakpoint-up(sm) {
            position: absolute;
            bottom: -34px;
            left: 32px;
          }
        }

        &.not-valid {
          display: inline-block;
          @include media-breakpoint-up(sm) {
            min-width: 400px;
          }
        }
      }

      .file-meta,
      .upload-progress-bar,
      .upload-remove {
        display: none;
      }

      .file {
        &-meta {
          display: none;

          &[data-fileType="application/pdf"] {
            .file-icon {
              background-image: url(../website/medias/icons/filetypes/pdf.png);
              background-size: cover;
            }
          }

          &[data-fileType="image/jpeg"] {
            .file-icon {
              background-image: url(../website/medias/icons/filetypes/icon_file_image@2x.png);
              background-size: contain;
            }
          }
        }

        &-name {
          @include copy-3;
          & {
            color: $color-grenke-grey-dark;
          }
        }

        &-icon {
          width: 19px;
          height: 30px;
          background-position: center;
          background-repeat: no-repeat;
          display: inline-block;
          background-size: contain;
          margin-right: 20px;
        }
      }

      .upload-progress-bar {
        .progress-bar {
          &-wrapper {
            position: relative;
            width: 100%;
          }

          &-default {
            background-color: $color-grenke-grey-warm;
            height: 20px;
            width: 100%;
            position: relative;

            .progress-bar-fill {
              position: absolute;
              top: 0;
              left: 0;
              height: 20px;
              width: 0%;
              background-color: $color-grenke-green;
              font-size: 10px;
              color: $color-grenke-white;
              line-height: 20px;
            }
          }
        }
      }

      .upload-remove {
        margin-top: 20px;
        @include media-breakpoint-up(sm) {
          margin-top: 30px;
        }
      }
    }
  }

  #phone-time-wrapper-js {
    padding-top: 30px;

    .phone-time-col-3,
    .phone-time-col-4 {
      margin-top: 20px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    .phone-time-col-5 {
      margin-top: 20px;
    }

    #phone-time-error {
      position: relative;
      height: 40px;
      @include media-breakpoint-up(md) {
        height: 30px;
      }

      .error {
        @include formfield-error;
        position: absolute;
        bottom: -5px;
        left: 32px;
      }
    }
  }

  .phone-time-wrapper-row + .formRow--row-medium {
    margin-top: 30px;
  }

  #OptinRequest-error {
    @include media-breakpoint-only(xs) {
      top: auto;
      position: absolute;
      bottom: -20px;
      left: 17px;
    }
    @include media-breakpoint-up(sm) {
      top: auto;
    }
  }

  div.row.formRow--row.person {
    display: none;
  }

  .phone-contact-box-mobile {
    right: 0px !important;
    margin-right: 1px;
    margin-left: 1px;
    display: block;
  }

  .phone-contact-box-desktop {
    position: absolute !important;
    bottom: 0;
    right: 6% !important;
    left: 6% !important;
    min-height: 115px;
  }
}

@import "contact-detail/default";
//alphabetical order
@import "contact-detail/ae";
@import "contact-detail/at";
@import "contact-detail/au";
@import "contact-detail/be";
@import "contact-detail/br";
@import "contact-detail/ca";
@import "contact-detail/ch";
@import "contact-detail/cz";
@import "contact-detail/cl";
@import "contact-detail/de";
@import "contact-detail/dk";
@import "contact-detail/en";
@import "contact-detail/es";
@import "contact-detail/fi";
@import "contact-detail/gb";
@import "contact-detail/gg";
@import "contact-detail/fr";
@import "contact-detail/hr";
@import "contact-detail/hu";
@import "contact-detail/it";
@import "contact-detail/ie";
@import "contact-detail/lu";
@import "contact-detail/lv";
@import "contact-detail/mt";
@import "contact-detail/nl";
@import "contact-detail/no";
@import "contact-detail/pl";
@import "contact-detail/pt";
@import "contact-detail/ro";
@import "contact-detail/se";
@import "contact-detail/sg";
@import "contact-detail/tr";
@import "contact-detail/us";
@import "contact-detail/se";
@import "contact-detail/si";
@import "contact-detail/sk";

@import "contact-detail/de-mla";
@import "contact-detail/it-mla";
@import "contact-detail/gb-mla";
@import "contact-detail/pt-mla";
@import "contact-detail/fr-mla";
@import "contact-detail/es-mla";
