/*
    styles for teamwall
*/

.teamwall-container {
    @include std-bottom-margin(false);
}

.teamwall-module {
    &.small {
        margin-bottom: 30px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 40px;
        }
        @include media-breakpoint-only(xl) {
            margin-bottom: 50px;
        }
    }

    &.big {
        margin-bottom: 40px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 50px;
        }
    }

    .teamwall {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        height: 100%;
        flex-wrap: wrap;
    }

    .img-wrapper {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        top: 0;
        &.big {
            width: 160px;
            height: 160px;
            max-width: 160px;
            max-height: 160px;
            img {
                width: 160px;
                height: 160px;
            }
            @include media-breakpoint-up(sm) {
                width: 296px;
                height: 296px;
                max-width: 296px;
                max-height: 296px;
                img {
                    width: 296px;
                    height: 296px;
                }
            }
        }
        &.small {
            width: 160px;
            height: 160px;
        }
    }
    .content-wrapper {
        box-sizing: border-box;
        background-color: $color-grenke-grey-warm;
        padding: 137px 30px 12px 30px;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        margin-top: -106px;
        z-index: 0;
        flex-wrap: wrap;

        .content-inner {
            height: 100%;
        }
        @include media-breakpoint-up(sm) {
            margin-top: -196px;
            padding: 236px 40px 24px 40px;
        }
        @include media-breakpoint-up(md) {
            padding: 246px 40px 24px 40px;
        }
        @include media-breakpoint-up(lg) {
            padding: 236px 40px 20px 40px;
        }
        @include media-breakpoint-up(xl) {
            padding: 246px 50px 33px 50px;
        }
        &.small {
            margin-top: -110px;
            padding: 140px 30px 30px 30px;
            @include media-breakpoint-up(sm) {
                margin-top: -110px;
                padding: 140px 30px 30px 30px;
            }
            @include media-breakpoint-up(md) {
                padding: 130px 30px 30px 30px;
            }
            @include media-breakpoint-up(lg) {
                padding: 140px 30px 30px 30px;
            }
            @include media-breakpoint-up(xl) {
                padding: 140px 30px 30px 30px;
            }
        }
    }
    .employee-name + .job-title {
        margin-top: 10px;
        @include media-breakpoint-up(sm) {
            margin-top: 15px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 20px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 15px;
        }
    }
    .employee-name-small + .job-title-small {
        margin-top: 5px;
    }
    .employee-name-small + .copy, .job-title-small + .copy {
        margin-top: 20px;
    }
    .copy + .copy {
        margin-top: 5px;
    }
    .job-title + .copy {
        margin-top: 10px;
        @include media-breakpoint-up(sm) {
            margin-top: 20px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 30px;
        }
    }
    .employee-name + .copy {
        margin-top: 10px;
        @include media-breakpoint-up(sm) {
            margin-top: 20px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 30px;
        }
    }
}
