form {
  width: 100%;

  footer & {
    margin-top: 20px;
  }
}

// hide number spinner
/* For Firefox */

input[type="number"] {
  -moz-appearance: textfield;
  font-weight: 300;
}

/* Webkit browsers like Safari and Chrome */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  font-weight: 300;
}

button[type="submit"] {
  border-color: transparent;
  border-style: none;
  border-width: 0;
  padding: 0;
  width: 100%;
}

input[type="text"],
input[type="email"],
input[type="number"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: 11px 17px;
  border: 1px solid $color-grenke-grey-light;
  background-color: $color-grenke-white;
  border-radius: 0;
  font-weight: 300;

  &:focus,
  &:active {
    outline: none;
  }

  .suggestion-module & {
    padding: 15px 17px;
    height: auto;
    min-height: 50px;
  }
}

input[type="email"] ~ .email-info-bubble {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url(../website/medias/icons/info_icon_popup.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  right: 16px;
  top: 14px;
}

.formRow {
  position: relative;
  width: 100%;
  align-self: flex-start;

  & + & {
    margin-top: 12px;
  }

  &--row + &--row {
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
  }

  &--row + &--row-large,
  &--row-medium + &--row-large {
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      margin-top: 60px;
    }
  }

  &--row + &--row-medium,
  &--row-medium + &--row-medium {
    margin-top: 30px;
  }

  &--row-large + &--row {
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
  }

  &--row-medium + &--row {
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
  }

  &--row-large + &--row-medium {
    margin-top: 30px;
  }

  &--row {
    &-headline {
      margin-bottom: 10px;
    }

    & + &-headline,
    &-medium + &-headline,
    &-large + &-headline {
      margin-top: 20px;
    }
  }

  &--col + &--col {
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }

  &--col-float.placeholder {
    @include media-breakpoint-only(xs) {
      display: none !important;
    }
  }

  &--col-float + &--col-float.visible {
    @include media-breakpoint-only(xs) {
      margin-top: 20px;
    }
  }

  &--col-float.visible + &--col-float.visible {
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
  }

  &--col-float.visible:nth-child(1) + &--col-float.visible {
    @include media-breakpoint-up(sm) {
      margin-top: 0px;
    }
  }

  &--col-headline {
    @include copy-1;
    & {
      color: $color-grenke-grey-dark;
    }
  }

  &--additional-text {
    @include copy-1;
    & {
      color: $color-grenke-grey-dark;
    }
  }

  label {
    margin: 0;
  }

  label.error,
  span.error {
    position: absolute;
    top: 43px;
    left: 17px;
    @include formfield-error;

    .blue-theme & {
      color: $color-grenke-error-on-blue;
    }

    &:not(.valid) + .placeholder {
      @include formfield-headline;
      top: -9px;
      background: transparent;
      transform: translateY(0);
      color: $color-grenke-red;
    }
  }

  textarea + label.error,
  textarea + span.error {
    top: auto;
    bottom: 6px;
    @include media-breakpoint-up(sm) {
      bottom: -15px;
    }
  }

  .placeholder {
    position: absolute;
    top: 50%;
    left: 10px;
    display: block;
    margin: 0 5px;
    padding: 0 5px;
    @include formfield-copy;
    color: $color-grenke-grey-dark-70;
    white-space: nowrap;
    transition: all, 0.2s;
    transform: translateY(-50%);
    pointer-events: none;
    user-select: none;
    font-weight: 300;

    .placeholder-underline {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 2px;
      left: 0;
      background-color: $color-grenke-grey-background-body;
      z-index: 1;
      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        height: 2px;
        bottom: 1px;
      }
    }

    .placeholder-text {
      position: relative;
      z-index: 2;
    }
  }

  &--item {
    display: block;
    width: 100%;
  }

  &--input,
  &--textarea {
    position: relative;
    width: 100%;
    outline: none;
    border: 1px solid $color-grenke-grey-light;
    color: $color-grenke-grey-dark;
    resize: none;
    transition: all 0.2s ease;
    @include formfield-copy;

    &.error {
      border: solid 1px $color-grenke-error;
      margin-bottom: 20px;
      @include media-breakpoint-only(xs) {
        margin-bottom: 20px;
      }
    }

    &-wrapper {
      position: relative;
      display: block;
      width: 100%;

      &.focus {
        .placeholder {
          @include formfield-headline;
          top: -9px;
          background: transparent;
          color: $color-grenke-blue;
          transform: translateY(0);
        }

        .error:not(.valid) + .placeholder {
          color: $color-grenke-error;
        }
      }

      &.active {
        .placeholder {
          @include formfield-headline;
          top: -9px;
          background-color: #ffffff;
          background: transparent;
          transform: translateY(0);
        }
      }

      .formRow--input,
      .formRow--textarea {
        &:focus {
          border-color: $color-grenke-blue;

          &.awesome-border {
            border-bottom: solid 1px $color-grenke-grey-light;
          }
        }
      }

      .formRow--input.error,
      .formRow--textarea.error {
        &:focus {
          border-color: $color-grenke-error;
        }
      }

      .search-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 42px;
        height: 100%;
        background: url(../website/medias/icons/search.png) center no-repeat;
        background-size: 17px 17px;
        cursor: pointer;

        .suggestion-module & {
          width: 50px;
          border-left: none;
          height: 50px;
        }
      }

      .euro-icon {
        position: absolute;
        right: 0;
        top: 2px;
        width: 42px;
        height: 100%;
        background-size: 17px 17px;
        cursor: text;
        display: flex;
        justify-content: center;

        &:after {
          content: "€";
          color: $color-grenke-blue;
          font-size: 25px;

          .blue-theme & {
            color: $color-grenke-white;
          }
        }
      }
    }
  }

  &--file {
    position: absolute;
    left: 0;
    top: 0;
    width: 0px;
    height: 0px;
    opacity: 0;

    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-label {
      width: 100%;
    }
  }

  &--textarea {
    min-height: 48px;
    resize: vertical;

    &-wrapper {
      textarea {
        @include formfield-textarea;
      }

      .placeholder {
        top: 24px;
      }
    }
  }

  &--checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &-wrapper {
      display: block;
      position: relative;
      padding-left: 50px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @include media-breakpoint-only(xs) {
        label.error {
          position: relative;
          left: -34px;
          bottom: -10px;
        }
      }
      @include media-breakpoint-up(sm) {
        label.error {
          bottom: -25px;
        }
      }
    }

    &-button {
      position: relative;
      padding-left: 50px;

      &:first-of-type {
        margin-top: 30px;
      }

      &:not(:first-of-type) {
        margin-top: 15px;
      }
    }

    &-error {
      position: relative;
      margin-top: 15px;
      padding-left: 17px;

      label.error {
        position: relative;
        top: 0;
        left: 0;
        bottom: 0;
      }
    }

    &-label {
      cursor: pointer;
      @include copy-1;
      & {
        color: $color-grenke-grey-dark;
      }

      a {
        @include a-text-link($color-grenke-blue);
      }

      label.error {
        top: auto;
      }

      input:checked ~ .formRow--checkbox-checkmark:after {
        display: block;
      }
    }

    .error:not(.valid) + &-checkmark {
      border: 1px solid $color-grenke-error;
    }

    &-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      border: 1px solid $color-grenke-grey-light;

      &:after {
        content: "";
        position: absolute;
        display: none;
        width: 21px;
        height: 15px;
        background-image: url(../website/medias/icons/check_blue.png);
        background-size: cover;
        background-position: center;
        top: 6px;
        left: 4px;
      }
    }
  }

  &--radio {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &-wrapper {
      display: block;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input:checked ~ .formRow--radio-checkmark:after {
        display: block;
      }

      @include media-breakpoint-only(xs) {
        label.error {
          position: relative;
          left: -34px;
          bottom: -10px;
        }
      }
      @include media-breakpoint-up(sm) {
        label.error {
          bottom: -25px;
        }
      }
    }

    &-button {
      position: relative;
      padding-left: 50px;

      &:first-of-type {
        margin-top: 30px;
      }

      &:not(:first-of-type) {
        margin-top: 15px;
      }
    }

    &-error {
      position: relative;
      margin-top: 15px;
      padding-left: 17px;

      label.error {
        position: relative;
        top: 0;
        left: 0;
        bottom: 0;
      }
    }

    &-label {
      cursor: pointer;
      @include copy-1;
      & {
        color: $color-grenke-grey-dark;
      }

      a {
        @include a-text-link($color-grenke-blue);
      }

      label.error {
        top: auto;
      }
    }

    .error:not(.valid) + &-checkmark {
      border: 1px solid $color-grenke-error;
    }

    &-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 1px solid $color-grenke-grey-light;

      &:after {
        content: "";
        position: absolute;
        display: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: $color-grenke-blue;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.square {
        border-radius: 0;
        &:after {
          width: 21px;
          height: 15px;
          border-radius: 0;
          background-image: url(../website/medias/icons/check_white.png);
          background-size: cover;
          background-position: center;
        }
      }
    }
  }

  &--dropdown {
    transition: all 0.2s ease;

    &-wrapper {
      position: relative;
      display: block;
      width: 100%;
      @include media-breakpoint-only(xs) {
        label.error {
          bottom: -19px;
        }
      }

      &.disabled {
        .formRow--dropdown-field {
          cursor: default;
          opacity: 0.3;
        }

        .placeholder {
          opacity: 0.5;
        }
      }

      &.active {
        .placeholder {
          @include formfield-headline;
          top: -9px;
          background: transparent;
          color: $color-grenke-grey-dark-70;
          transform: translateY(0);

          &.focus {
            color: $color-grenke-blue;
          }
        }

        .formRow--input:not(:focus):not(:hover) {
          & ~ .placeholder {
            color: $color-grenke-grey-dark-70;
          }
        }
      }

      .formRow--dropdown-field {
        caret-color: transparent !important;
        @include formfield-copy;

        &:focus {
          border-color: $color-grenke-blue;
        }
      }
    }

    .error:not(.valid) + &-field {
      border: 1px solid $color-grenke-error;
      margin-bottom: 20px;
      @include media-breakpoint-only(xs) {
        margin-bottom: 20px;
      }
    }

    .error:not(.valid) ~ &-list {
      border: 1px solid $color-grenke-error;
    }

    &-overlay {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      z-index: 501; // to be over scrollToTop
    }

    &-field {
      cursor: pointer;
      z-index: 2;
      width: 100%;
      padding: 12px 39px 12px 17px;
      border: 1px solid $color-grenke-grey-light;
      background-color: $color-grenke-white;
      border-radius: 0;
      color: $color-grenke-grey-dark;

      .suggestion-module & {
        padding: 16px 39px 16px 17px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 6px;
        background-image: url(../website/medias/icons/arrow_blue_down.png);
        background-size: 10px 6px;
        background-repeat: no-repeat;
        background-position: center;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.focus {
        border-color: $color-grenke-blue;

        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &-list {
      cursor: pointer;
      z-index: 502; // to be over scrollToTop
      display: none;
      width: 100%;
      border: solid 1px $color-grenke-blue;
      border-top-color: $color-grenke-grey-light;
      margin-top: -1px;
      background-color: #fcfcfb;
      cursor: pointer;
      max-height: 50vh;
      overflow-y: scroll;

      &-item {
        padding: 10px 15px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: $color-grenke-white;
        color: $color-grenke-grey-dark-70;
        @include transition(background-color, 0.5s);

        a {
          min-height: unset;

          .btn-caption {
            // color: $color-grenke-grey-dark-70;
            line-height: 24px;
          }
        }

        &:hover {
          color: $color-grenke-grey-dark;
          background-color: $color-grenke-grey-warm;
        }

        &.current {
          background-color: $color-grenke-grey-warm;
        }
      }
    }

    &-select {
      position: absolute;
      height: 0;
      width: 0;
      opacity: 0;
      bottom: 0;
    }
  }
}

.blue-theme {
  .formRow--row,
  &.formRow--row,
  &.formRow--row-headline,
  .formRow--row-headline {
    .formRow--col-headline {
      color: $color-grenke-white;
    }

    .error:not(.valid) + .placeholder {
      color: $color-grenke-error-on-blue;
    }

    .formRow--input {
      border: solid 1px $color-grenke-grey-light;
      color: $color-grenke-grey-light;
      background-color: $color-grenke-blue-white-10;

      &-wrapper {
        .placeholder {
          color: $color-grenke-grey-light;

          .placeholder-underline {
            background-color: $color-grenke-blue-white-10;
          }
        }

        &.focus {
          .formRow--input {
            color: $color-grenke-white;
          }

          .placeholder {
            background: transparent;
            color: $color-grenke-white;

            .placeholder-underline {
              background-color: $color-grenke-blue;
            }
          }

          .error:not(.valid) + .placeholder {
            color: $color-grenke-error-on-blue;
          }
        }

        &.active {
          .formRow--input {
            color: $color-grenke-white;
          }

          .placeholder {
            background: transparent;
            color: $color-grenke-grey-light;
          }

          .placeholder-underline {
            background-color: $color-grenke-blue;
          }
        }

        .formRow--input {
          &:focus {
            border-color: $color-grenke-white;
          }

          &.error:focus {
            border-color: $color-grenke-error-on-blue;
          }
        }
      }

      &.error {
        border: solid 1px $color-grenke-error-on-blue;
      }
    }

    .formRow--checkbox {
      &-label {
        color: $color-grenke-white;

        a {
          @include a-text-link($color-grenke-white);
        }
      }

      &-checkmark {
        border: 1px solid $color-grenke-grey-light;

        &:after {
          background-image: url(../website/medias/icons/check_white.png);
        }
      }

      &-wrapper {
        .formRow--checkbox-checkmark {
          &:focus,
          &:active {
            border-color: $color-grenke-white;
            box-shadow: 0 0 5px 0 $color-grenke-white;
          }
        }

        .error:not(.valid) + .formRow--checkbox-checkmark {
          border-color: $color-grenke-error-on-blue;
        }
      }
    }

    .formRow--dropdown {
      &-wrapper {
        .placeholder {
          color: $color-grenke-grey-light;

          .placeholder-underline {
            background-color: $color-grenke-blue-white-10;
          }
        }

        &.active {
          .placeholder {
            background: transparent;
            color: $color-grenke-grey-light;

            .placeholder-underline {
              background-color: $color-grenke-blue;
            }

            &.focus {
              color: $color-grenke-white;
            }
          }

          .formRow--input:not(:focus):not(:hover) {
            & ~ .placeholder {
              color: $color-grenke-grey-light;
            }
          }

          .formRow--dropdown-field {
            color: $color-grenke-white;
          }
        }

        .formRow--dropdown-field {
          &:focus {
            border-color: $color-grenke-white;
          }
        }

        .error:not(.valid) + .formRow--dropdown-field {
          border-color: $color-grenke-error-on-blue;
        }

        .error:not(.valid) ~ .formRow--dropdown-list {
          border-color: $color-grenke-error-on-blue;
        }
      }

      &-field {
        border: 1px solid $color-grenke-grey-light;
        background-color: $color-grenke-blue-white-10;
        color: $color-grenke-grey-light;

        &:after {
          background-image: url(../website/medias/icons/arrow_white_down.png);
        }

        &.focus {
          border-color: $color-grenke-white;
        }
      }

      &-list {
        border: solid 1px $color-grenke-white;
        background-color: $color-grenke-blue-white-10;

        &-item {
          background-color: $color-grenke-blue-white-10;
          color: $color-grenke-white;
          @include transition(background-color, 0.5s);

          &:hover {
            color: $color-grenke-grey-dark;
            background-color: $color-grenke-white;
          }

          &.current {
            background-color: $color-grenke-white;
          }
        }
      }
    }

    .formRow--textarea {
      border: solid 1px $color-grenke-grey-light;
      color: $color-grenke-grey-light;
      background-color: $color-grenke-blue-white-10;

      &-wrapper {
        .placeholder {
          color: $color-grenke-grey-light;
        }

        &.focus {
          .formRow--textarea {
            color: $color-grenke-white;
          }

          .placeholder {
            background: transparent;
            color: $color-grenke-white;
          }

          .error:not(.valid) + .placeholder {
            color: $color-grenke-error-on-blue;
          }
        }

        &.active {
          .formRow--textarea {
            color: $color-grenke-white;
          }

          .placeholder {
            background: transparent;
            color: $color-grenke-grey-light;
          }
        }

        .formRow--textarea {
          &:focus {
            border-color: $color-grenke-white;
          }

          &.error:focus {
            border-color: $color-grenke-error-on-blue;
          }
        }
      }

      &.error {
        border: solid 1px $color-grenke-error-on-blue;
      }
    }

    .formRow--radio-button {
      .formRow--radio-label {
        color: $white;
      }
    }
  }
}

.formRow--dropdown-list {
  overflow-y: auto;
}

//suggestion-component
.suggestion-module .bg-grenke-grey-warm {
  .active,
  .focus {
    .placeholder-underline {
      background-color: $color-grenke-grey-warm;
    }
  }
}
