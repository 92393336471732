.event-teaser-overview {
  .event-teaser-overview-headline + .event-teaser-overview-link {
    margin-top: 40px;
  }
}

.event-teaser-overview + .event-teaser-module {
  margin-top: 40px;
}

.event-teaser-module {
  &:last-child {
    @include std-bottom-margin(false);
  }
  .event-teaser-box-col + .event-teaser-info-col {
    margin-top: 30px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  .event-teaser-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: $color-grenke-grey-warm;
    @include media-breakpoint-only(xs) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @include media-breakpoint-only(sm) {
      padding-left: 60px;
      padding-right: 60px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 180px;
    }

    @include media-breakpoint-up(xl) {
      padding: 40px;
      min-height: 200px;
    }
    &:hover {
      text-decoration: none;
    }

    .event-teaser-box-day,
    .event-teaser-box-location {
      display: block;
    }
    .event-teaser-box-day + .event-teaser-box-month-year {
      margin-top: 10px;
    }
    .event-teaser-box-month-year + .event-teaser-box-location {
      margin-top: 20px;
    }
  }
  .event-teaser-info {
    @include media-breakpoint-up(xl) {
      padding-left: 15px;
    }
    .event-teaser-headline + .event-teaser-subtitle {
      margin-top: 10px;
    }
    .event-teaser-headline + .event-teaser-bullets {
      margin-top: 20px;
    }
    .event-teaser-subtitle + .event-teaser-bullets {
      margin-top: 15px;
    }
    .button-box {
      margin-top: 20px;
    }
    .btn-box {
      margin-top: 15px;
    }
  }
  & + & {
    margin-top: 60px;
  }
  #event-overview-page & + & {
    @include media-breakpoint-up(xl) {
      margin-top: 80px;
    }
  }
}

#event-overview-page #event-overview-container {
  @include std-bottom-margin(false);
}
