#event-page {
    .back-to-event-overview-row {
        margin-top: 50px;
    }
    .back-to-event-overview-row + .event-detail-headline-row {
        margin-top: 40px;
        @include media-breakpoint-up(lg) {
            margin-top: 50px;
        }
    }
    .event-detail-headline-row + .event-detail-content-row {
        margin-top: 30px;
        @include media-breakpoint-up(md) {
            margin-top: 50px;
        }
        @include std-bottom-margin(false);
    }
    .event-detail-category-col + .event-detail-title-col {
        margin-top: 10px;
    }
    .event-detail-title-col + .event-detail-subline-col {
        margin-top: 15px;
    }
    .event-detail-info-box-day + .event-detail-info-box-month-year {
        margin-top: 10px;
    }
    .event-detail-info-box-month-year + .event-detail-info-box-location {
        margin-top: 30px;
        .button-wrapper {
            margin-top: 20px;
        }
    }
    .event-detail-info-col + .event-detail-description-col {
        margin-top: 30px;
        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    }
    .event-detail-description-bullets + .event-detail-description-text {
        margin-top: 30px;
    }
    .event-detail-description-text + .button-row {
        margin-top: 30px;
        .button-col + .button-col {
            margin-top: 30px;
            @include media-breakpoint-only(md) {
                margin-top: 0;
            }
        }
    }
    .event-detail-info-box {
        background-color: $color-grenke-grey-warm;
        padding: 30px;
        @include media-breakpoint-only(xl) {
            padding: 50px;
        }
    }

    .event-detail-info-box-day {
        margin-bottom: 30px;
        @include media-breakpoint-only(xs) {
            margin-bottom: 20px;
        }
    }
    address + address {
        margin-top: 10px;
    }

}