.portfolio-module {
    @include std-bottom-margin(false, -30px, false);
    .portfolio-stage {
        height: 260px;
        @include media-breakpoint-up(sm) {
            height: 244px;
        }
        @include media-breakpoint-up(lg) {
            height: 326px;
        }
        @include media-breakpoint-up(lg) {
            height: 430px;
        }
        img {
            width: 100%;
        }
    }
    .portfolio-textbox {
        margin-top: -40px;
        background-color: $color-grenke-grey-warm;
        padding: 0;
        @include media-breakpoint-up(md) {
            margin-top: -50px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: -80px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: -100px;
        }
        .portfolio-text-wrapper {
            padding: 30px;
            @include media-breakpoint-up(md) {
                padding: 40px;
            }
            @include media-breakpoint-up(xl) {
                padding: 50px;
            }
            h3 {
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 30px;
            }
        }
    }
    .portfolio-tile {
        height: 160px;
        background-color: $color-grenke-blue;
        color: $color-grenke-white;
        padding: 15px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: space-between;
        transition: box-shadow .5s;
        &:hover {
            box-shadow: 0 6px 6px 0 rgba(0,0,0,0.26), 0 10px 20px 0 rgba(0,0,0,0.19);
            .btn {
                left: 10px;
            }
        }
        @include media-breakpoint-up(lg) {
            height: 135px;
        }
        @include media-breakpoint-up(xl) {
            height: 190px;
        }

        .use-hyphens {
            hyphens: none;
        }
    }
    .portfolio-tile-link-slide {
        width: 100%;
        padding: 0 20px;
        @include media-breakpoint-only(xs) {
            margin-bottom: 30px;
        }
    }

    .portfolio-tile-headline {
        width: 100%;
        // 3 lines * lineheight
        max-height: 3*28px;
        overflow: hidden;
        hyphens: auto;
        word-break: break-word;
        @include media-breakpoint-only(lg) {
            // 3 lines * lineheight
            max-height: 3*18px;
        }
        @include media-breakpoint-only(xl) {
            // 3 lines * lineheight
            max-height: 3*28px;
        }
        &.small-headline {
            font-size: 14px;
            line-height: 18px;
        }
    }
    .mobile-tiles {
        margin: 0 -30px;
    }
    .btn-box.small-headline {
        .btn-caption {
            font-size: 12px !important;
            line-height: 15px !important;
        }
    }
}

.tab-container-portfolio {
    padding-bottom: 30px;
}