$font-family-bold: "HelveticaNeueLTW02-85Heavy", Arial, sans-serif;
$font-family-italic: "HelveticaNeueLTW02-86HvIt", Arial, sans-serif;
$font-family-regular: "HelveticaNeueLTW02-55Roman", Arial, sans-serif;

.swiper-container-horizontal {
    overflow: hidden;
    .portfolio-module &.tab-container-navi {
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
        }
    }
    .tab-search-page &.tab-container-navi {
        margin-bottom: 17px;
        @include media-breakpoint-up(xl) {
            margin-bottom: 27px;
        }
    }
}

.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;

    .tab-search-page .tab-container-tab-image & {
        opacity: 0 !important;
        height: 0px;

        &.swiper-slide-active {
            opacity: 1 !important;
            height: auto;
        }
    }

    .tab-search-page .tab-container-navi & {
        padding: 0 12px 10px;
        width: auto;

        .tab-item {
            padding: 10px 25px;
            position: relative;
            border: 1px solid transparent;
            width: 100%;
            cursor: pointer;
            color: $color-grenke-grey-dark;
            transition: all .3s;

            &:hover {
                color: $color-grenke-blue;
            }

            &:before {
                content: "";
                position: absolute;
                margin: auto;
                top: 100%;
                left: 0;
                right: 0;
                width: 10px;
                height: 10px;
                margin-top: -4px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                border-right: 1px solid $color-grenke-white-light;
                border-bottom: 1px solid $color-grenke-white-light;
                transition: all .3s;
            }

            &:after {
                content: "";
                position: absolute;
                margin: auto;
                top: 100%;
                left: 0;
                right: 0;
                width: 12px;
                height: 1px;
                border-top: 1px solid $color-grenke-white-light;
            }
        }

        &:last-child {
            margin-right: 0;
            padding: 0 0 10px 12px;
        }

        &:first-child {
            padding: 0 12px 10px 0;
        }

        &.active {
            .tab-item {
                color: $color-grenke-blue;
                border: 1px solid $color-grenke-grey-warm-icon;

                &:before {
                    border-right: 1px solid $color-grenke-grey-warm-icon;
                    border-bottom: 1px solid $color-grenke-grey-warm-icon;
                }

                &:hover {
                    border-color: $color-grenke-blue;

                    &:before {
                        border-right: 1px solid $color-grenke-blue;
                        border-bottom: 1px solid $color-grenke-blue;
                    }
                }
            }
        }
    }
    .tab-search-page .tab-container-navi & {
        @include media-breakpoint-up(xl) {
            padding: 1px 1px 7px 0;
        }
    }

    .article-tile {
        &:last-child {
            margin-bottom: 50px;
        }
    }

    .magazine-box {
        height: 610px;
        overflow: hidden;
        position: relative;

        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
        }

        &:hover {
            img {
                transition: transform .3s;
                transform: scale(1.05);
            }
        }

        .image-component {
            position: relative;
            overflow: hidden;
            height: 100%;
        }

        img {
            transition: transform .3s;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            z-index: -1;
        }

        .article-link {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .box-content {
            display: block;
            position: absolute;
            color: #fff;
            width: 100%;
            left: 0;
            bottom: 0;

            padding: 0 15px 35px;
            @include media-breakpoint-up(sm) {
                padding: 0 60px 30px;
            }
            @include media-breakpoint-up(md) {
                padding: 0 40px 30px;
            }
            @include media-breakpoint-up(lg) {
                padding: 0 20px 30px;
            }
            @include media-breakpoint-up(xl) {
                padding: 0 20px 35px;
            }
            .flag-teaser {
                font-size: 17px;
                line-height: 24px;
                padding: 8px 10px;
                display: inline-block;
                font-family: $font-family-italic;
                letter-spacing: 0;
                margin-bottom: 15px;
                color: #fff;
                text-transform: uppercase;

                &.grenke {
                    background-color: $color-grenke-blue;
                }

                &.report {
                    background-color: $color-grenke-explorer-olive;
                }

                &.rookie {
                    background-color: $color-grenke-explorer-rouge;
                }

                &.good-idea {
                    background-color: $color-grenke-explorer-mint;
                }

                &.well-done {
                    background-color: $color-grenke-explorer-curry;
                }

                &.digital {
                    background-color: $color-grenke-explorer-aqua;
                }
            }
            .article-teaser-headline {
                font-size: 40px;
                line-height: 50px;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-family: $font-family-bold;
                letter-spacing: 0;
                margin: 0;
            }
            .intro-text {
                margin-top: 10px;
                margin-bottom: 20px;
                font-family: $font-family-regular;
                letter-spacing: 0;
                font-size: 18px;
                line-height: 26px;
            }
            .article-info {
                z-index: 2;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 16px;
                color: $color-grenke-grey-dark-70;
                font-family: $font-family-regular;
                .icon-clock {
                    height: 16px;
                    width: 16px;
                    background: url("../website/medias/icons/clock_V02@3x.svg") no-repeat center;
                    background-size: cover;
                }

                .icon + span {
                    margin-left: 7px;
                    height: 16px;
                    color: $color-grenke-grey-dark-70;
                }
            }

            .box-shadow {
                z-index: -1;
                opacity: .7;
                background-image: linear-gradient(180deg,transparent,#000 74%);
                position: absolute;
                left: 0;
                bottom: 0;
                height: calc(100% + 30px);
                width: 100vw;
                overflow-x: hidden;

                @include media-breakpoint-up(lg) {
                    width: calc(100% + 379px)!important;
                }
            }
        }
    }
}

