.search-result-page {
  .search-result-box {
    margin-bottom: 50px;
    background-color: $color-grenke-grey-warm-60;

    hr {
      width: 100%;
    }
  }

  a {
    &:hover {
      .search-results-list-title {
        text-decoration: underline;
        text-decoration-color: $color-grenke-blue;
      }

      /*
      TODO: Button hover Effekt + kein Unterstreichen mehr
      .search-result {
        transition: all 300ms;
        transform: scale(1.01);
      }
      */
    }
  }

  .search-result-breadcrumb {
    font-size: 12px;
    color: $color-grenke-grey-dark-70;
    text-transform: uppercase;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    pointer-events: none;

    .breadcrumb-home-icon {
      width: 16px;
      height: 16px;
      top: auto;
    }

    .breadcrumb-home-icon + .arrow-image {
      position: relative;
      top: -2px;
    }
  }

  .result-heading {
    margin-bottom: 40px;
    margin-top: 45px;
    @include media-breakpoint-up(sm) {
      margin-top: 60px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 100px;
    }
  }

  .search-category {
    margin-bottom: 10px;
  }

  .copy-text {
    margin-top: 10px;
  }

  .bold {
    font-family: 'Helvetica-regular', Verdana, Arial, 'sans-serif';
  }

  mark {
    font-family: 'Helvetica-regular', Verdana, Arial, 'sans-serif';
    background-color: transparent;
    padding-right: 0;
    padding-left: 0;
    color: $color-grenke-blue;
  }

  .arrow-image {
    background: url("../website/medias/icons/arrow_search.png") no-repeat 0 3px;
    margin: 0 10px;
    padding-left: 7px;
  }

  .button-box {
    @extend .ml-auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
