.location-finder-module {
  @include std-bottom-margin(false);

  .map-small {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 105px; // xs
    @include media-breakpoint-only(sm) {
      height: 160px;
    }
    @include media-breakpoint-only(md) {
      height: 215px;
    }
    @include media-breakpoint-only(lg) {
      height: 285px;
    }
    @include media-breakpoint-only(xl) {
      height: 400px;
    }
    img {
      max-width: 100%;
    }
  }

  .map-large {
    width: 100%;
    height: 250px; // xs
    @include media-breakpoint-only(sm) {
      height: 360px;
    }
    @include media-breakpoint-only(md) {
      height: 400px;
    }
    @include media-breakpoint-only(lg) {
      height: 535px;
    }
    @include media-breakpoint-only(xl) {
      height: 700px;
    }
    img {
      max-width: 100%;
    }
  }

  .search-bar {
    margin-bottom: 30px;
    min-height: 315px;
    margin-top: -20px;
    @include media-breakpoint-only(sm) {
      margin-top: -30px;
    }
    @include media-breakpoint-only(md) {
      margin-top: -40px;
    }
    @include media-breakpoint-only(lg) {
      margin-top: -50px;
    }
    @include media-breakpoint-only(xl) {
      margin-top: -90px;
    }

    .background-wrapper {
      padding: 0 0 30px 0;
      @include media-breakpoint-up(sm) {
        padding: 30px 0;
      }
      @include media-breakpoint-up(lg) {
        padding: 40px 0;
      }
    }

    .heading-text {
      padding: 50px 10px 50px 40px;
      min-height: 315px;
      @include media-breakpoint-between(xs, md) {
        display: none !important;
      }
      @include media-breakpoint-only(xl) {
        padding: 50px 20px 50px 50px;
      }

      &:after {
        content: "";
        position: absolute;
        right: -30px;
        top: 50%;
        width: 0;
        height: 0;
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
        border-left: 17px solid $color-grenke-grey-warm;
        margin-top: -17px;
        z-index: 1;
      }
    }
    .search-form {
      background-color: $color-grenke-blue;
      color: $color-grenke-white;
      height: 100%;
      width: calc(100% + 1px);
      position: relative;
      .wrapper {
        padding: 30px 30px 30px 30px;
        @include media-breakpoint-only(sm) {
          padding: 30px 30px 30px 30px;
        }
        @include media-breakpoint-only(md) {
          padding: 30px 60px 30px 60px;
        }
        @include media-breakpoint-only(lg) {
          padding: 30px 60px 30px 60px;
        }
        @include media-breakpoint-only(xl) {
          padding: 30px 80px 30px 80px;
        }
        a:hover {
          color: $color-grenke-blue;
        }
        .formRow--row {
          margin-bottom: 20px;
        }
        .dropdown-row {
          padding-right: 72px;
          position: relative;
        }
        .loc-btn-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          width: 41px;
          .btn#loc-btn {
            min-height: 42px;
            padding: 2px;
          }
          .btn-icon {
            height: 24px;
            width: 24px;
            margin: 0;
            .geolocation-icon {
              background-image: url("../website/medias/icons/geolocation_search@2x.png");
              background-size: contain;
              background-repeat: no-repeat;
              height: 24px;
              width: 24px;
            }
          }
        }
        #search-btn {
          margin-top: 30px;
        }
      }
    }
  }

  .infoBox {
    background-color: $color-grenke-grey-warm;
    color: $color-grenke-blue;
    min-width: 200px;
    min-height: 100px;
    padding: 12px;
    font-family: Helvetica;
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.26), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
    > img {
      // the close icon
      width: 10px;
      height: 10px;
    }
    .btn {
      margin-top: 10px;
      min-height: 32px !important;
      img {
        max-width: none;
      }
    }
    .raw-address {
      display: none;
    }
    &:after {
      // left arrow
      content: "";
      position: absolute;
      left: -8px;
      top: 50%;
      margin-top: -9px;
      z-index: 1;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px 9px 9px 0;
      border-color: transparent $color-grenke-grey-warm transparent;
    }
  }

  .result-list {
    margin-top: 30px;
    @include media-breakpoint-only(sm) {
      margin-top: 40px;
    }
    @include media-breakpoint-only(md) {
      margin-top: 50px;
    }
    @include media-breakpoint-only(lg) {
      margin-top: 80px;
    }
    @include media-breakpoint-only(xl) {
      margin-top: 90px;
    }
  }

  .result-container {
    margin-top: 40px;
    .result {
      padding-bottom: 30px;
      border-bottom: 1px solid $color-grenke-grey-warm;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .result + .result {
      margin-top: 50px;
    }
    .result-headline {
      margin: 15px 0 20px;
      @include media-breakpoint-up(sm) {
        margin: 15px 0 25px;
      }
    }
    .active {
      .row > div {
        background-color: $color-grenke-grey-warm;
      }
      .active-result- {
        &top {
          padding-top: 15px;
        }
        &bottom {
          padding-bottom: 15px;
        }
      }
    }
    .distance {
      margin-top: 10px;
      padding-right: 18px;
      text-align: right;
      background-repeat: no-repeat;
      background-position: right center;
      color: $color-grenke-grey-dark;
    }
    h4 {
      margin-bottom: 20px;
    }
    h5 {
      @include copy-3;
      & {
        text-transform: uppercase;
        color: $color-grenke-blue;
      }
    }
    .copy {
      @include copy-1;
      & {
        color: $color-grenke-grey-dark;
      }
    }
    .about {
      padding-bottom: 10px;
    }
    .result-col {
      margin-top: 20px;
      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }
  }

  #result-template {
    display: none;
  }
}

.location-finder-module-page {
}

.background-box {
  background-color: transparent;
  @include media-breakpoint-up(lg) {
    background-color: $color-grenke-grey-warm !important;
  }
}
