.breadcrumb-home-icon {
  background: transparent url("../website/medias/v2/icons/GRENKE_Icon_home_grey.svg") center center/contain no-repeat;
  display: inline-block;
  position: relative;
  top: 2px;
  width: 18px;
  height: 18px;

  @include media-breakpoint-up(md) {
    width: 20px;
    height: 20px;
  }
}

.breadcrumb-home-icon:hover {
  background-image: url("../website/medias/v2/icons/GRENKE_Icon_home_blue.svg");
}
