body {
  font-family: 'Helvetica';
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border: none;
  background-color: transparent;
}

/* helper */
.grenke-blue {
  color: $color-grenke-blue;
}

.underline {
  text-decoration: underline;
}

.std-bottom-margin {
  &.row {
    margin-bottom: 40px;
  }
}

.bo {
  border: 1px dotted #000000;
}

.ou {
  outline: 1px solid #000000;
}

.mobile-fix {
  overflow-y: hidden;
  position: relative;
  max-height: 0;
}

/* helper end */

// ToDo write function
.px-15 {
  padding: 0 15px;
}

.bg-grenke-grey-warm {
  background-color: $color-grenke-grey-warm;
}

.flex-1-1-0 {
  flex: 1 1 0;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}


.body-wrapper {
  max-width: 2600px;
  margin: 0 auto;
  overflow: hidden;
  /* TODO must be replaced */
  background-color: $color-grenke-grey-background-body;
  @media (min-width: 3000px) {
    border-right: 1px solid $color-grenke-grey-warm;
    border-left: 1px solid $color-grenke-grey-warm;
  }
}

.image-wrapper {
  .tile & {
    margin: 30px auto 40px;
    width: 60px;
    height: 60px;
    @include media-breakpoint-up(sm) {
      margin: 40px auto 50px;
    }
  }
}

.hide-while-load {
  display: none;
}


.none-appearance {
  background-color: transparent;
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border: none;
    outline: none;
  }

  &:active {
    border: none;
    outline: none;
  }

  &.dot {
    padding: 0;
  }
}

header + :not(.stage-module-js, .stage-module, .notfound-wrapper, .location-finder-module-page, main) {
  margin-top: calc(#{$bottom-gap-module-xs});
  @include media-breakpoint-up(sm) {
    margin-top: 50px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 80px
  }
  @include media-breakpoint-up(xl) {
    margin-top: 100px
  }
}

#toggle-piwik {
  @include media-breakpoint-only(xs) {
    min-height: 362px;
  }
}

.ie-10-correct {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex: 0 0 50%;
    max-width: 50% !important;
  }
}

.no-wordbreak {
  hyphens: none;
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
}

#ppms_cm_popup_responsive_wrapper_id {
  overflow-y: auto !important;
}

#ppms_cm_consent_wrapper {
  min-height: 200px !important;
}

[id^="_no-margin-bottom-"],
[id^="_no-margin-bottom-"] [class*="-module"] {
  margin-bottom: 0 !important;
}

.tooltip {
  font-family: 'Helvetica';
  width: 300px;
  display: flex;
  justify-content: center;
  top: auto !important;
  bottom: 100%;
  left: 50% !important;
  transform: translateX(-50%) !important;

  @include media-breakpoint-down(lg) {
    width: 200px;
  }
  @include media-breakpoint-down(md) {
    width: 110px;
  }
  @include media-breakpoint-down(sm) {
    width: 130px;
  }

  .arrow {
    left: 50% !important;
    transform: translateX(-50%);

    &:before {
      border-top-color: $color-grenke-blue;
    }
  }

  .tooltip-inner {
    background-color: $color-grenke-blue;
    color: $color-grenke-white;
    width: auto;
    max-width: none;
    border-radius: 0;
  }
}

