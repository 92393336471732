#newspage {
  .news-detail {
    margin-top: 30px;
    @include std-bottom-margin(false);
    @include media-breakpoint-up(sm) {
      margin-top: 50px;
    }

    .pipe-separator {
      border-left: 1px solid $color-grenke-grey-dark;
      line-height: 16px;
      position: relative;
      top: 0;
      display: inline-block;
      width: 1px;
    }
    .news-date {
      line-height: 1;
    }
    .article-intro {
      h1 {
        text-transform: none;
      }
    }
  }
  .image-wrapper {
    overflow: hidden;
    height: 190px;
    @include media-breakpoint-up(sm) {
      height: 293px;
    }
    @include media-breakpoint-up(sm) {
      height: 397px;
    }
    @include media-breakpoint-up(lg) {
      height: 250px;
    }
    @include media-breakpoint-up(xl) {
      height: 338px;
    }
  }
  .intro-text-container {
    .meta-container + .text-container {
      margin-top: 20px;
    }

    .text-container {
      .title,
      .subtitle {
        word-wrap: break-word;
        h2 {
          overflow-wrap: break-word;
          word-wrap: break-word;
          hyphens: auto;
        }
      }
    }
  }
  .text-container {
    .title + .subtitle {
      margin-top: 15px;
    }
  }
  .img-container + .intro-text-container {
    margin-top: 30px;
    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  .language-select-container {
    .active {
      color: $color-grenke-blue;
      font-family: "Helvetica-regular";
    }
    .news-language {
      line-height: 1;
    }
    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }
  .language-select-container + .article-meta-container {
    margin-top: 20px;
    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }
  .article-text {
    p + p {
      margin-top: 20px;
    }
  }
  .to-overview + .article-intro {
    margin-top: 50px;
    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 50px;
    }
  }
  .article-intro + .article-text {
    margin-top: 30px;
    @include media-breakpoint-up(sm) {
      margin-top: 40px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }
  }
  .article-text .copy.copy-text {
    @include media-breakpoint-up(lg) {
      column-count: 2;
      column-gap: 30px;
    }
  }
  .article-text + .button-container {
    margin-top: 30px;
  }
}
