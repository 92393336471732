/*
	Styles for mainnavigation
*/

.meta-margin {
  height: 60px;
  background: $white;
}

.navigation-box {
  border-bottom: 1px solid $color-grenke-grey-warm;
}

.navigation-main {
  height: 65px;
  @include media-breakpoint-up(lg) {
    height: 70px;
  }

  &.mobile-open {
    border-bottom: 1px solid $color-grenke-grey-warm;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: $color-grenke-white;
    height: 66px;
  }

  .burger-menu-container {
    width: 65px;
    cursor: pointer;
    margin-right: -15px;
    transition: background-color 0.5s linear;

    &.active {
      background-color: $color-grenke-grey-warm;
    }
  }

  .burger-menu {
    width: 20px;
    height: 16px;

    .start,
    .center,
    .end {
      height: 2px;
      width: 20px;
      background-color: $color-grenke-blue;
      position: absolute;
      transition: transform 0.25s linear, top 0.25s linear 0.25s;
    }

    .start {
      top: 0;
    }

    .center {
      top: 7px;
    }

    .end {
      top: 14px;
    }

    &.active {
      .start,
      .end,
      .center {
        transition: top 0.25s linear, transform 0.25s linear 0.25s;
      }

      .start,
      .end {
        top: 7px;
        transform: rotate(45deg);
      }

      .center {
        transform: rotate(-45deg);
      }
    }
  }

  .search {
    width: 65px;
    background: url("../website/medias/icons/search.png") no-repeat center;
    cursor: pointer;
    background-size: 24px;
    @include media-breakpoint-up(lg) {
      width: 20px;
      margin-left: 40px;
      background-size: contain;
    }
  }

  .navigation-grenke-logo {
    width: 120px;
    min-width: 120px;
    background: url("../website/medias/logos/grenke-logo.svg") no-repeat left center;
    background-size: contain;

    &.weblease-logo {
      width: 180px;
      min-width: 180px;
      background: url("../campaign/medias/logos/weblease-logo-small.png") no-repeat left center;
      background-size: contain;
    }

    @include media-breakpoint-up(lg) {
      width: 140px;
      min-width: 140px;
      background: url("../website/medias/logos/grenke-logo.svg") no-repeat left 35%;
      background-size: contain;
      &.weblease-logo {
        width: 180px;
        min-width: 180px;
        background: url("../campaign/medias/logos/weblease-logo-medium.png") no-repeat left center;
        background-size: contain;
      }
      &.closed-logo,
      &.no-meta {
        background-position: left 50%;
      }
    }
    @include media-breakpoint-up(xl) {
      width: 180px;
      min-width: 180px;
      background: url("../website/medias/logos/grenke-logo.svg") no-repeat left 22%;
      background-size: contain;
      &.weblease-logo {
        width: 180px;
        min-width: 180px;
        background: url("../campaign/medias/logos/weblease-logo.png") no-repeat left center;
        background-size: contain;
      }
      &.closed-logo {
        background-position: left 50%;
      }
    }

    &.no-meta {
      background-position: left center !important;
    }

    .logo-link {
      width: 100%;
      height: 100%;
    }

    &.logo-transition {
      @include transition(background-position, 0.5s);
    }
  }

  .navigation-campaign-align {
    transition: margin-bottom 0.5s;
    @include media-breakpoint-up(lg) {
      margin-bottom: 22px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 21px;
    }

    &.closed {
      @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: 12px;
      }
    }
  }

  .navigation-list-item {
    caret-color: transparent !important;
    text-transform: uppercase;
    margin-left: 30px;
    color: $color-grenke-grey-dark;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      @include media-breakpoint-up(md) {
        color: $color-grenke-blue;
      }
    }

    &.active {
      color: $color-grenke-blue;
      font-family: "Helvetica-regular";

      &:after {
        content: "";
        position: absolute;
        margin: auto;
        bottom: -25px;
        left: 0;
        right: 0;
        width: 12px;
        height: 1px;
        border-top: 1px solid $color-grenke-white;
        z-index: 1001;
      }

      &:before {
        content: "";
        position: absolute;
        margin: auto;
        bottom: -29px;
        left: 0;
        right: 0;
        width: 10px;
        height: 10px;
        margin-top: -4px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        border-right: 1px solid $color-grenke-grey-warm;
        border-bottom: 1px solid $color-grenke-grey-warm;
      }

      &.two-lined {
        &:after {
          bottom: -14px;
        }

        &:before {
          bottom: -18px;
        }
      }
    }

    &.menu-item-active {
      color: $color-grenke-blue;
      font-family: "Helvetica-regular";

      &.two-lined {
        &:after {
          bottom: -14px;
        }

        &:before {
          bottom: -18px;
        }
      }
    }

    &.campaign {
      &.active {
        border-bottom: 1px solid $color-grenke-grey-light;
        color: $color-grenke-grey-dark;
      }

      &.before {
        transform: rotate(-90deg);
      }
    }
  }
}

/* Mobile Navigation */

.mobile-wrapper {
  //min-height: 100vh;
  position: fixed;
  top: 66px;
  left: 0;
  //padding-bottom: 178px;
  z-index: 1001;
  height: calc(100% - 66px);
  overflow-y: auto;
  width: 100%;
  background-color: $color-grenke-white;
  display: none;

  &.active {
    display: block;
  }

  .mobile {
    &.navigation-list {
      background-color: $color-grenke-grey-warm;
      border-bottom: 1px solid $color-grenke-grey-light;

      &.open {
        display: block;
      }

      .navigation-list-item {
        border-bottom: 1px solid $color-grenke-grey-light;
        color: $color-grenke-grey-dark;
        position: relative;
        padding: 17px 0 17px 10px;

        &:last-of-type {
          border: none;
        }

        &:hover {
          text-decoration: none;
          @include media-breakpoint-up(md) {
            color: $color-grenke-blue;
          }
        }

        &.active {
          color: $color-grenke-blue;
          font-family: "Helvetica-regular";
          border-bottom: 1px solid transparent;

          &:after {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }

        &.menu-item-active {
          color: $color-grenke-blue;
          font-family: "Helvetica-regular";
        }

        &:after {
          content: "";
          position: absolute;
          top: 24px;
          right: 10px;
          background: url("../website/medias/icons/arrow_blue_down.svg") no-repeat right;
          background-size: contain;
          width: 17px;
          height: 9px;
        }

        &.d-block.anchor:after {
          transform: rotate(-90deg);
        }
      }

      .campaign {
        .navigation-list-item {
          &.active {
            color: inherit;
            font-family: inherit;
            border-bottom: inherit;
          }
        }
      }
    }

    &.navigation-meta {
      background-color: $color-grenke-white;
      border-bottom: 1px solid $color-grenke-grey-light;

      &.open {
        display: block;
      }

      .meta-nav-item {
        padding: 17px 0 17px 10px;
        border-bottom: 1px solid $color-grenke-grey-light;
        margin-right: 0;
        position: relative;
        text-decoration: none;
        width: 100%;
        text-align: left;

        &:after {
          content: "";
          position: absolute;
          top: 24px;
          right: 10px;
          background: url("../website/medias/icons/arrow_blue_down.svg") no-repeat right;
          background-size: contain;
          width: 17px;
          height: 9px;
        }

        &.last-item {
          border-bottom: none;
        }

        &.language-select:after,
        &.country-switch:after,
        &.country-switch.active:after {
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }

        &.active {
          border-bottom: 1px solid transparent;

          &:after {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }

        &.language-switch {
          &:after {
            background: none;
          }

          .mobile-language-switch-item {
            margin-left: 25px;

            &:first-child {
              margin-left: 0;
            }

            &.active {
              text-decoration: underline;
            }
          }
        }

        &.country-switch {
          border-top: 1px solid $color-grenke-grey-light;

          .flag {
            width: 20px;
            height: 15px;
            margin: 0 10px 1px 0;

            &.campaign-flag {
              margin-top: 3px;
            }
          }
        }
      }

      .dropdown-navigation {
        .menu-items {
          margin: 30px 0 40px 0;

          &.tertiary {
            margin: 20px 0 30px 0;
            @include media-breakpoint-up(md) {
              margin: 20px 0 40px 0;
            }
          }

          .last-secound-level {
            margin-top: 10px;
          }

          .extra-button-distance {
            margin-bottom: 15px;
          }
        }

        &.active {
          border-bottom: 1px solid $color-grenke-grey-light;
          border-top: 1px solid $color-grenke-grey-light;
        }
      }
    }
  }
}

/* sticky navigation*/
.sticky-box {
  display: none;
  background-color: $color-grenke-white;
  border-bottom: 1px solid $color-grenke-grey-warm;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;

  .navigation-grenke-logo {
    height: 100%;
  }
}

/* sticky navigation */

/* scroll-navigation behaviour */
.navigation-transition {
  transition: top 0.5s;
}

.navigation-retracted {
  &:not(.no-meta) {
    @include media-breakpoint-up(lg) {
      top: -60px;
    }
  }
}

/* scroll-navigation behaviour */

/* navigation flyouts */
.flyout-container {
  position: absolute;
  left: 0;
  top: 130px;
  width: 100%;
  background-color: $color-grenke-white;
  border: 1px solid $color-grenke-grey-warm;
  box-shadow: 0 10px 20px 0 $color-grenke-black-10;
  display: none;
  z-index: 1000;

  &.transition {
    transition: top 0.5s;
  }

  .flyout {
    width: 100%;
    height: 100%;
    min-height: 100px;
    @include transition(max-height, 0.5s);

    &.flyout-scroll {
      overflow-y: scroll;
      max-height: calc(100vh - 131px);

      .navigation-retracted + & {
        max-height: calc(100vh - 131px + 60px);
      }
    }
  }

  .btn-close-container {
    height: 45px;
    width: 100%;
  }

  .unternehmen {
    .img-box {
      display: none !important;
    }
  }

  .item-box {
    margin: 0 0 50px 0;
    display: none;
    width: 100%;

    &.active {
      display: flex !important;
      flex-wrap: wrap;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 20px;
      &.solutions,
      &.products {
        margin-top: 20px;
      }
    }

    h6 {
      margin: 0 0 10px;
    }

    &.company,
    &.products {
      margin-top: 15px;

      .img-box {
        display: none;
      }
    }

    &.products {
      margin-top: 30px;

      .button-align-right {
        margin-top: -15px;
      }
    }

    &.products-images {
      .button-align-right {
        margin-top: -15px;
      }
    }

    &.career {
      @include media-breakpoint-up(lg) {
        margin-top: 6px;
      }

      .btn-box {
        &:first-child {
          margin: 0;
        }
      }

      h6 {
        margin: 20px 0 10px;
      }
    }

    &.solutions {
      h6 {
        margin: 0px 0 10px;
      }
    }

    @include media-breakpoint-up(lg) {
      &.sectors {
        .item-box-content:hover {
          .btn {
            left: 10px;
          }
        }
      }
      .navigation-news:hover {
        .btn {
          left: 10px;
        }
      }
    }

    &.news-events,
    &.investor-relations,
    .navigation-news {
      .content-wrapper {
        flex-direction: column;

        p {
          font-family: "Helvetica";
          font-size: 13px;
          color: $color-grenke-grey-dark;
          letter-spacing: 0;
          line-height: 20px;
          overflow: hidden;
          max-height: calc(20px * 3);
          margin-bottom: 20px;

          &.navigation-news-headline {
            max-height: calc(22px * 1);
            overflow: hidden;
            margin: 20px 0 10px;
          }
        }
      }

      .img-box {
        height: 205px;
        margin-bottom: 0;
      }
    }

    .img-box {
      height: 115px;
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  .teaser-box {
    margin: 0 15px;
    display: none;
    color: $color-grenke-blue;

    &.active {
      display: block;
    }

    &.career {
      margin: 24px 0 50px 0;
      min-height: 210px;

      .teaser-box-item {
        .inside-row {
          padding: 30px;
        }

        .teaser-text {
          margin-bottom: 30px;
        }

        h6 {
          margin-bottom: 10px;
        }

        p {
          font-family: "Helvetica";
          font-size: 13px;
          color: $color-grenke-grey-dark;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }

    .teaser-box-item {
      display: flex;
      height: 100%;
      background-color: $color-grenke-grey-warm-60;
      padding: 0;
      color: $color-grenke-blue;

      &:hover {
        text-decoration: none;
      }

      &:hover {
        .btn-tertiary {
          left: 10px;
        }
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }

      &:hover {
        background-color: $color-grenke-grey-warm;
      }

      .teaser-text {
        width: 100%;
        justify-self: start;
        align-self: flex-start;
        font-size: 19px;
        line-height: 29px;
      }

      .teaser-button {
        width: 100%;
        justify-self: end;
        align-self: flex-end;
      }

      .inside-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 20px 0 20px;
      }

      .btn-box {
        margin-bottom: 0;
      }
    }
  }

  .show-all-button-container {
    top: 23px;
    right: 70px;

    &.smaller {
      top: 7px;
    }
  }
}

.mobile {
  &.dropdown-navigation {
    background-color: $color-grenke-white;
    display: none;
    width: calc(100% + 30px);
    position: relative;
    left: -15px;
    overflow-x: hidden;

    .teaser-box {
      padding: 0 25px;
    }

    .menu-items {
      padding: 0 25px;
    }

    .item-box-content {
      margin-bottom: 15px;

      // GOAL: Remove vertical margin between columns on mobile, if there is no column headline:
      // "IF ".item-box-content" has another directly following ".item-box-content",
      // which does NOT contain a ".btn-menu-link"
      // THEN remove the margin of the first ".btn-box" inside ".item-box-content"
      &:not(:has(+ .item-box-content .btn-menu-link)) {
        .btn-box:first-child {
          margin: 0;
        }
      }

      &:last-child {
        margin-bottom: 5px;
      }

      > .btn-box,
      > .accordeon-container {
        > .btn-box {
          margin-bottom: 0;
          padding-bottom: 0;
          padding-top: 5px;
        }

        &:first-child {
          margin: 20px 0 0;
        }
      }

      &.no-child {
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 5px;
        }

        &.first {
          .btn-menu-link {
            margin-top: 20px;
          }
        }

        .btn-menu-link {
          margin: 0;

          .btn-main-menu {
            margin-bottom: 0;
          }
        }

        &.news-events,
        &.investor-relations,
        &.solutions {
          margin-top: 0;

          &:first-child {
            margin-top: 20px;
          }

          &:last-child {
            margin-bottom: 20px;
          }
        }
      }

      h6 {
        margin: 25px 0 0 0;

        &.first {
          margin: 15px 0 0 0;
        }
      }

      .btn-menu-link + .btn-menu-item-link {
        margin: 0;
      }

      &.news-events {
        margin-top: 20px;

        .btn-menu-link {
          display: none;
        }
      }

      .btn-menu-item-link {
        padding-bottom: 0;
        margin-top: 5px;
      }
    }

    .teaser-box-item {
      background-color: $color-grenke-grey-warm-60;
      color: $color-grenke-blue;
      margin: 0;
      padding: 15px 0 0;
      @include media-breakpoint-up(md) {
        padding: 20px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .teaser-box {
      &.career {
        .inside-row {
          padding: 15px;
          width: 100%;

          h6 {
            margin-bottom: 10px;
          }

          p {
            font-family: "Helvetica";
            font-size: 13px;
            color: $color-grenke-grey-dark;
            letter-spacing: 0;
            line-height: 18px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .teaser-item {
      margin-top: 20px;
      color: $color-grenke-blue;

      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }

    .button-align-right {
      margin: 30px 0 0;

      &.btn-overview {
        margin: 10px 0 -15px;
      }
    }

    .career .button-box {
      padding: 0;
    }
  }

  .meta-mobile-headline + .meta-mobile-button,
  .meta-mobile-button + .meta-mobile-button {
    margin-top: 15px;
  }

  .meta-mobile-button + .meta-mobile-headline {
    margin-top: 30px;
  }

  .meta-mobile-menu-list {
    margin: 10px 0;
  }
}

/* Search Flyout */

.search {
  position: relative;
  caret-color: transparent !important;

  &.open {
    &:after {
      content: "";
      position: absolute;
      margin: auto;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 12px;
      height: 1px;
      border-top: 3px solid $color-grenke-white;
      z-index: 1001;
      @include media-breakpoint-up(lg) {
        bottom: -25px;
        border-top: 1px solid $color-grenke-white-light;
      }
    }

    &:before {
      content: "";
      position: absolute;
      margin: auto;
      bottom: -5px;
      left: 0;
      right: 0;
      width: 10px;
      height: 10px;
      margin-top: -4px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      border-right: 1px solid $color-grenke-grey-warm;
      border-bottom: 1px solid $color-grenke-grey-warm;
      @include media-breakpoint-up(lg) {
        bottom: -29px;
      }
    }
  }

  &.mobile {
    &.open {
      background: url("../website/medias/icons/close_menu_@2x.png") no-repeat center;
      background-size: 18px;
    }
  }
}

.search-flyout-container {
  border: 1px solid $color-grenke-grey-warm;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: $color-grenke-white;
  z-index: 1000;
  top: 130px;

  &.transition {
    transition: top 0.5s;
  }

  &.desktop {
    height: 0;
    max-height: 157px;

    &.open {
      display: block;
    }
  }

  &.mobile {
    top: 65px;
    max-height: 140px;
    @include media-breakpoint-up(sm) {
      max-height: 152px;
    }

    &.open {
      display: block;
      position: fixed;
    }
  }

  .search-form {
    margin: 30px 0 30px;
    @include media-breakpoint-up(sm) {
      margin: 30px 0 40px;
    }

    .search-input {
      height: 70px;
      border: 3px solid $color-grenke-grey-light;
      padding: 19px 84px 19px 20px;
      @include media-breakpoint-up(sm) {
        height: 80px;
        padding: 26px 99px 26px 25px;
      }
      @include media-breakpoint-up(lg) {
        height: 85px;
        padding: 24px 104px 25px 23px;
      }

      &:focus {
        outline: none;
      }
    }

    .search-submit {
      background: url("../website/medias/icons/lupe_search@2x.png") no-repeat center;
      border: none;
      color: transparent;
      position: absolute;
      top: 3px;
      right: 3px;
      background-size: 30px;
      height: 64px;
      width: 64px;
      @include media-breakpoint-up(sm) {
        height: 74px;
        width: 74px;
        background-size: 33px;
      }
      @include media-breakpoint-up(lg) {
        height: 79px;
        width: 79px;
        background-size: 30px;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        background: $color-grenke-grey-warm url("../website/medias/icons/lupe_search@2x.png") no-repeat center;
        cursor: pointer;
        background-size: 30px;
        @include media-breakpoint-up(lg) {
          background-size: 33px;
        }
      }
    }
  }
}

.awesomplete {
  span {
    display: none;
  }

  ul {
    background-color: $color-grenke-white;
    list-style-type: none;
    padding: 95px 25px 30px;
    margin: 0;
    border: 3px solid $color-grenke-grey-light;
    border-top: none;
    position: relative;
    max-height: 390px;
    overflow-x: hidden;
    overflow-y: auto;

    &:before {
      width: calc(100% - 60px);
      height: 75px;
    }

    li {
      cursor: pointer;
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
      }

      mark {
        color: $color-grenke-blue;
        background-color: $color-grenke-white;
        padding: 0;
      }
    }
  }

  .awesomplete-before {
    width: calc(100% - 60px);
    //height: 75px;
    padding: 40px 0 10px;
    left: 0;
    color: $color-grenke-grey-dark;
    height: 75px;
    border-bottom: 2px solid $color-grenke-grey-light;
    position: absolute;
    margin-left: 27px;
    background-color: $color-grenke-white;
    top: 73px;
    @include media-breakpoint-up(sm) {
      top: 85px;
    }
  }
}

.meta-flyout-container {
  padding-top: 15px;
  background-color: transparent;
  display: none;
  width: 340px;
  height: 0;
  z-index: 1100;
  top: 54px;
  transition: top 0.5s;

  .padding-box {
    padding: 40px 30px 30px 30px;
  }

  &.open {
    display: block;
  }

  .meta-flyout-content {
    background-color: $color-grenke-white;
    border: 1px solid $color-grenke-grey-warm;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

    &:before {
      content: "";
      position: absolute;
      margin: auto;
      top: 14px;
      left: 50%;
      width: 10px;
      height: 10px;
      margin-top: -4px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      border-right: 1px solid $color-grenke-grey-warm;
      border-bottom: 1px solid $color-grenke-grey-warm;
      z-index: 9999;
      background-color: $color-grenke-white;
    }

    &:after {
      transition: none;
      content: "";
      position: absolute;
      margin: auto;
      top: 15px;
      left: calc(50% - 1px);
      width: 12px;
      height: 1px;
      border-top: 1px solid #fff;
      z-index: 9999;
    }

    .meta-flyout-headline + .meta-flyout-button,
    .meta-flyout-button + .meta-flyout-headline,
    .meta-flyout-button + .meta-flyout-button {
      margin-top: 20px;
    }

    .meta-flyout-menu-list {
      margin: 10px 0;
    }
  }
}

//dealer
.dealer-flyout-container {
  display: none;
  width: 340px;
  height: 0;
  z-index: 1100;
  top: 54px;
  transition: top 0.5s;

  .padding-box {
    padding: 40px 30px 30px 30px;
  }

  &.open {
    display: block;
  }

  .dealer-menu-list {
    margin: 10px 0 10px 0;
  }

  .dealer-buttons {
    .btn-box:first-child {
      margin-bottom: 13px;
      margin-top: 0;
    }
  }
}

.customer-flyout-container {
  display: none;
  width: 320px;
  height: 0;
  z-index: 1100;
  top: 54px;
  transition: top 0.5s;
  position: relative;

  .padding-box {
    padding: 40px 30px 30px 30px;
  }

  &.open {
    display: block;
  }

  .customer-menu-list {
    margin: 10px 0 10px 0;
  }
}

.customer-flyout-container,
.customer-mobile-login {
  .customer-buttons {
    .btn-box {
      margin-bottom: 13px;
      margin-top: 0;
    }
  }

  .customer-section-button {
    margin-bottom: 30px;

    &.last {
      margin-bottom: 0;
    }

    .btn-box {
      margin: 0;

      span:first-child {
        line-height: 14px;
      }
    }

    .btn-box + .btn-box {
      margin-top: 20px;
    }
  }

  .customer-section-headline {
    margin-bottom: 20px;
  }
}

.location-flyout-container {
  display: none;
}

// special case: grenke-group: investor-relations
#investor-relations {
  .btn-main-menu {
    min-height: 22px;
  }

  .main-btn + .btn-menu-item-link,
  .main-btn + .accordeon-container {
    margin-top: 13px;
  }
}
