.link-download-list {
    &.link-list {
        @include std-bottom-margin(false);
    }
    &.expanded {
        .item-wrapper + .item-wrapper {
            margin-top: 30px;
        }
        .filetype-image-wrapper {
            display: flex;
            justify-content: center;
        }
        .item-wrapper .link-item .link-triangle-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
    .item-wrapper {
        &[data-visible="false"] {
            display: none;
        }
        .link-item {
            position: relative;
            padding: 5px 0;
            &:hover {
                .link-link {
                    text-decoration: underline;
                }
                .link-source {
                    text-decoration: none;
                }
            }
            a {
              &:hover {
                text-decoration: none;
              }
            }
            .link-triangle-wrapper {
                display: flex;
                justify-content: flex-end;
                position: absolute;
                right: 0;
                @include media-breakpoint-up(sm) {
                    position: relative;
                    right: unset;
                }
                .link-triangle {
                    background: url("../website/medias/icons/arrow_linkitem.png") 0 no-repeat;
                    background-size: contain;
                    height: 18px;
                    margin-top: 4px;
                    min-width: 18px;
                    @include media-breakpoint-up(sm) {
                        //margin-top: 8px;
                    }
                }
            }
        }
    }
    .item-wrapper + .item-wrapper {
        margin-top: 10px;
        @include media-breakpoint-up(md) {
            margin-top: 20px;
        }
    }
    .list-headline {
        margin-bottom: 15px;
        @include media-breakpoint-only(md) {
            margin-bottom: 5px;
        }
    }
    .link-list-download-item-wrapper {
        position: relative;
        padding: 5px 0;
        .link-list-icon-wrapper {
            display: flex;
            justify-content: flex-end;
        }
        &:hover {
            .download-link p{
                text-decoration: underline;
            }
            .download-data {
                text-decoration: underline;
            }
            .link-link {
                text-decoration: underline;
            }
            .link-source {
                text-decoration: none;
            }
        }
        .link-list-download-link-icon {
            top: 1px;
            left: 0;
            @include media-breakpoint-up(sm) {
                left: 15px;
            }
        }
        .filetype-icon-wrapper {
            top: 1px;
            right: 0;
            width: 100%;
            @include media-breakpoint-up(sm) {
                right: 15px;
            }
            .icon {
                height: 26px;
            }
        }
        .filetype-icon-wrapper {
            width: 100%;
            .icon {
                height: 26px;
            }
            .thumb-image {
                width: 100%;
            }
        }
        .link-download-thumb-image {
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 38px;
            @include media-breakpoint-up(sm) {
                height: 41px;
            }
            @include media-breakpoint-up(md) {
                height: 61px;
            }
            @include media-breakpoint-up(lg) {
                height: 85px;
            }
            @include media-breakpoint-up(xl) {
                height: 50px;
            }
            &.top {
                align-items: flex-start;
            }

            &.bottom {
                align-items: flex-end;
            }
            img {
                width: 100%;
                position: absolute;

                &.top{
                    top:0;
                }

                &.bottom{
                    bottom: 0;
                }
            }
        }
    }
    &.reports-downloads {
        .reports-downloads-wrapper {
            @include std-bottom-margin(false);
        }
        .reports-control {
            @include media-breakpoint-up(sm) {
                margin-bottom: 20px;
            }
            @include media-breakpoint-up(md) {
                margin-bottom: 35px;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: 65px;
            }
            @include media-breakpoint-up(xl) {
                margin-bottom: 25px;
            }
            .reports-mobile-gap {
                margin-bottom: 20px;
                @include media-breakpoint-up(sm) {
                    margin-bottom: 0;
                }
            }
            .dropdown {
                width: 100%;
                border: solid 1px $color-grenke-grey-light;
                cursor: pointer;
                background-color: $color-grenke-white;
                .dropdown-title {
                    top: -7px;
                    color: $color-grenke-grey-dark;
                    margin: 0 10px;
                    background-color: $color-grenke-white-light;
                    line-height: 1;
                    font-size: 13px;
                    padding: 0 5px;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
                .dropdown-wrapper {
                    margin: 10px 15px;
                    .dropdown-arrow {
                        width: 10px;
                        img {
                            width: 100%;
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
            .dropdown-content {
                width: 100%;
                border: solid 1px $color-grenke-grey-light;
                margin-top: -1px;
                background-color: $color-grenke-white-light;
                cursor: pointer;
                max-height: 50vh;
                overflow-y: scroll;
                .dropdown-content-item {
                    padding: 10px 15px;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    background-color: $color-grenke-white;
                    @include transition(background-color, 0.5s);
                    &:hover {
                        background-color: $color-grenke-grey-warm;
                    }
                    &.current {
                        background-color: $color-grenke-grey-warm;
                    }
                }
            }
            .dropdown-underlay {
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                background-color: transparent;
                opacity: 0;
            }
            .reports-filter-reset {
                margin: 20px 0 35px 0;
                cursor: pointer;
                @include media-breakpoint-up(sm) {
                    margin: 20px 0 5px 0;
                }
                @include media-breakpoint-up(md) {
                    margin: 0;
                }
            }
        }
        .reports-year-dropdown {
            z-index: 4;
        }
        .reports-topics-dropdown {
            z-index: 5;
            margin-bottom: 20px;
            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }
        }
        .dotdotdot {
            overflow: hidden;
            max-height: 50px;
            @include media-breakpoint-up(sm) {
                max-height: 55px;
            }
            @include media-breakpoint-up(lg) {
                max-height: 55px;
            }
            @include media-breakpoint-up(xl) {
                max-height: 60px;
            }
        }
        .no-hits {
            display: none;
            text-align: center;
            padding: 180px 0;
        }
        .back-to-top-height {
            height: 0px;
            margin-top: 88px;
        }
    }
}
