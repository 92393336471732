.suggestion-module {
  position: relative;
  z-index: 1;
  @include std-bottom-margin(false);
  .suggestion-section {
    height: 52px;
    background-color: $color-grenke-blue;
    display: flex;
    align-items: center;
    padding: 0 25px 0 30px;
    position: relative;
    justify-content: space-between;
    @include media-breakpoint-up(sm) {
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 52px;
      justify-content: normal;
    }
    @include media-breakpoint-up(md) {
      width: 83px;
    }
    @include media-breakpoint-up(xl) {
      width: 68px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 30px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $color-grenke-blue;
      @include media-breakpoint-up(sm) {
        bottom: auto;
        top: 40px;
        right: -10px;
        border-right: none;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid $color-grenke-blue;
      }
      @include media-breakpoint-up(lg) {
        top: 50px;
      }
    }
    .icon-wrapper {
      width: 30px;
      height: 30px;
      justify-self: end;
      &.icon- {
        &document {
          background: url("../website/medias/icons/light-bulb.svg") no-repeat 50%;
          background-size: contain;
        }
        &info {
          background: url("../website/medias/icons/icon_highlight_info@2x.png") no-repeat 50%;
          background-size: contain;
        }
        &whitepaper {
          background: url("../website/medias/icons/light-bulb.svg") no-repeat 50%;
          background-size: contain;
        }
        &location {
          background: url("../website/medias/icons/compass_suggestion@2x.png") no-repeat 50%;
          background-size: contain;
        }
      }
      @include media-breakpoint-up(sm) {
        align-self: flex-start;
        margin: 25px auto 0;
        width: 40px;
        height: 40px;
      }
      @include media-breakpoint-up(md) {
        margin: 25px auto 0;
        width: 50px;
        height: 50px;
      }
      @include media-breakpoint-up(lg) {
        margin: 30px auto 0;
      }
    }
  }
  .suggestion-content {
    padding: 10px 30px 30px 30px;
    @include media-breakpoint-up(sm) {
      padding: 30px 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
    .suggestion-texts {
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
      }
      .headline {
        @include media-breakpoint-up(lg) {
          margin-bottom: 10px;
        }
      }
    }
    .suggestion-dropdown {
      margin-bottom: 15px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
    .suggestion-choice {
      margin-bottom: 15px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
      .sugestion-choice-text {
        @include media-breakpoint-up(lg) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .suggestion-input {
      margin-bottom: 30px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
      .awesomplete {
        position: relative;
        max-height: 50vh;
        input[type="text"] {
          padding: 12px 47px 12px 17px;
        }
        ul {
          padding: 0;
          position: absolute;
          z-index: 1001;
          top: 49px;
          left: 0;
          border: solid 1px $color-grenke-blue;
          border-top: solid 1px $color-grenke-grey-light;
          width: 100%;
          li {
            padding: 10px 15px;
            margin: 0;
            @include transition(background-color, 0.5s);
            &:hover {
              background-color: $color-grenke-grey-warm;
              mark {
                background-color: $color-grenke-grey-warm;
              }
            }
            mark {
              @include transition(background-color, 0.5s);
            }
          }
        }
      }
    }
  }
}
