.data-protection {
  margin-top: -50px;
  margin-bottom: 50px;
  #ppms_cm_privacy_settings_form_link_header_id {
    font-size: 24px !important;
    text-transform: none;
    color: rgba(116, 114, 108, 1) !important;
    line-height: 29px !important;
    margin-bottom: 12px;
  }
  .ppms_cm_privacy_settings_widget {
    border: 1px solid $color-grenke-grey-warm;
    background-color: $color-grenke-grey-background;
    padding: 24px;
  }
  .btn-box {
    margin-top: 10px;
    .ppms_cm_privacy_settings_button_show {
      display: flex;
      justify-content: center;
      height: 60px;
      .btn-txt {
        margin: 7px 0px;
      }

    }
  }
}