.fgs-result {
    @include std-bottom-margin(false);
    .fgs-stage-result {
        position: relative;
        z-index: 1;

        margin: 0 0 27px 0;
        @include media-breakpoint-up(sm) {
            margin: 0 0 27px 0;
        }
        @include media-breakpoint-up(md) {
            margin: 0 0 17px 0;
        }
        @include media-breakpoint-up(lg) {
            margin: 0 0 17px 0;
        }
        @include media-breakpoint-up(xl) {
            margin: -200px 0 37px 0;
        }
        &:after {
            content: "";
            width: 0px;
            height: 0px;
            -webkit-transform:rotate(360deg);
            border-style: solid;
            border-width: 17px 16px 0 16px;
            border-color: $color-grenke-blue transparent transparent transparent;
            position: absolute;
            left: 50%;
            bottom: -17px;
        }
        .fgs-stage-result-inner-container {
            background-color: $color-grenke-blue;
            padding: 30px;
            @include media-breakpoint-up(lg) {
                padding: 30px;
            }
            .result-box {
                margin-top: 30px;
                @include media-breakpoint-up(md) {
                    margin-top: 45px;
                }
                @include media-breakpoint-up(lg) {
                    margin-top: 35px;
                }
                @include media-breakpoint-up(xl) {
                    margin-top: 40px;
                }
                .fgs-result-info-wrapper {
                    flex-direction: column;
                    .fgs-result-info-description {
                        margin-top: 5px;
                        @include media-breakpoint-up(xl) {
                            margin-top: 10px;
                        }
                    }
                    .info-bubble {
                        right: -30px;
                        top: calc(50% - 10px);
                        position: absolute;
                    }
                }
            }
        }
    }
    .fgs-back-button {
        margin-bottom: 30px;
    }
    .fgs-result-form {
        .last-name-margin {
            @include media-breakpoint-only(xs) {
                margin-top: 20px;
            }
        }
    }
    #DisclaimerFoerdergutschein-error {
        top: auto;
        bottom: -10px;
        display: block;
        @include media-breakpoint-up(sm) {
            bottom: -25px;
        }
    }
}