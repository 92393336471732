.dashboard-group {
    .analysts-container {
        padding: 15px 0 0;
        h4 {
            margin: 30px 0;
            @include media-breakpoint-up(lg) {
                margin: 0;
            }
        }
        .analysts-headline {
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
                margin-bottom: 15px;
            }
        }
        .analyst {
            margin: 0 0 20px;
            &:nth-child(4) {
                margin: 0 0 20px;
            }
            .analyst-data {
                &.copy {
                    &.green {
                        color: $color-grenke-green;
                    }
                }
            }
        }
        .btn-box {
            margin-bottom: 20px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                margin-top: 16px;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .dashboard-min-height {
            .fact-sheet-content {
                min-height: 266px;
            }
        }
    }
    .fact-sheet-content {
        padding: 0;
        @include media-breakpoint-up(lg) {
            padding: 15px 0 0 0;
        }
        h4 {
            margin: 30px 0;
            @include media-breakpoint-up(lg) {
                margin: 0;
            }
        }
        .button-box {
            margin-bottom: 20px;
            padding-left: 0;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                padding-left: 15px;
            }
            .btn-box {
                padding-left: 15px;
            }
        }
    }
    .last-col {
        .fact-sheet-content {
            .button-box {
                margin-bottom: 20px;
            }
        }
    }
    .first-col {
        .container-padding-left {
            padding-left: 0;
        }
        .fact-sheet-content {
            .btn-box {
                padding-left: 15px;
                @include media-breakpoint-up(lg) {
                    padding-left: 0;
                }
            }
        }
    }
    .left-border {
        &:after {
            width: 0;
            height: calc(100% - 15px);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color-grenke-grey-light;
            margin: 15px 0 -15px;
            @include media-breakpoint-up(lg) {
                width: 1px;
            }
        }
    }
    .right-border {
        &:after {
            width: 0;
            height: calc(100% - 15px);
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            background-color: $color-grenke-grey-light;
            margin: 15px 0 -15px;
            @include media-breakpoint-up(lg) {
                width: 1px;
            }
        }
    }
    .bottom-border {
        &:after {
            height: 1px;
            width: calc(100% - 15px);
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $color-grenke-grey-light;
            margin: 0 15px 0;
        }
    }
}
