/*
	styles for header and header elements
 */

header {
	background-color: $color-grenke-white;
	position: relative;
	z-index: $z-index-header;
}

/*
	Styles for metanavigation
*/
.navigation-meta {
	caret-color: transparent !important;
	@include media-breakpoint-up(lg) {
		height: 30px;
		margin-top: 30px;
	}
	.none-appearance {
		cursor: default;
	}
	.meta-nav-item {
		color: $color-grenke-blue;
		margin-right: 20px;
		align-self: center;
		font-family: 'Helvetica-regular';
		cursor: pointer;
		display: flex;
		@include media-breakpoint-up(lg) {
			padding-right: 0;
			padding-left: 0;
		}
		&.language-select {
            margin-right: 0;
        }
		&.country-switch {
			cursor: pointer;
			.flag{
				width: 20px;
				height: 15px;
				margin: 0 10px 1px 20px;
				&.campaign-flag {
					margin-top: 3px;
				}
			}
		}
		&:last-child {
			margin-right: 0;
		}
		a {
			color: $color-grenke-blue;
			margin-right: 20px;
			cursor: pointer;
			align-self: center;
			font-family: 'Helvetica-regular';
			margin: 0;
			&:hover {
				text-decoration: none;
			}
		}
		.meta-nav-dropdown {
			width: 1px;
			height: 20px;
			margin-left: 15px;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				top: 8px;
				right: 0;
				background: url("../website/medias/icons/arrow_blue_down.svg") no-repeat right;
				background-size: contain;
				width: 10px;
				height: 6px;
			}
		}
		.meta-nav-separator {
			width: 1px;
			height: 20px;
			border-left: 1px solid $color-grenke-grey-light;
			margin-left: 20px;
			position: relative;
			align-self: center;
			&.dropdown {
				margin-left: 35px;
				&:after {
					content: "";
					position: absolute;
					top: 8px;
					right: 21px;
					background: url("../website/medias/icons/arrow_blue_down.svg") no-repeat right;
					background-size: contain;
					width: 10px;
					height: 6px;
				}
			}
			.login-dealer & {
				margin-left: 30px;
			}
			&.language-switch-separator {
				margin-left: 0;
			}
		}
		.language-switch {
			margin-left: 15px;
			&.first-switch {
				margin-left: 20px;
			}
			&.active {
				text-decoration: underline;
			}
		}
	}
	.login-dealer, .login-customer {
		position: relative;
		width: 100%;
		text-align: left;
		cursor: pointer;
		@include media-breakpoint-up(lg) {
			width: auto;
		}
		.meta-nav-separator {
			margin-left: 35px;
			&:after {
				content: "";
				position: absolute;
				top: 8px;
				right: 21px;
				background: url("../website/medias/icons/arrow_blue_down.svg") no-repeat right;
				background-size: contain;
				width: 10px;
				height: 6px;
			}
		}
	}
	.login-customer {
		position: relative;
	}
	.language-select {
		position: relative;
		cursor: pointer;
	}
}

/*
	styles for noscript-banner
*/
.notification-banner {
    margin-top: -11px;
    color: $color-grenke-grey-dark;
    padding: 20px 0;

    @include media-breakpoint-down(md) {
        margin-top: 0;
        padding: 10px 0;
    }

    .container{
        text-align: center;
        justify-content: center;
    }

    .copy{
        padding-right: 40 + 20 + 0px;
        text-align: center;

		@include media-breakpoint-up(sm) {
			font-size: 15px;
			line-height: 19px;
		}
		@include media-breakpoint-up(xl) {
			font-size: 16px;
			line-height: 20px;
		}
    }

    .icon-info {
        min-width: 20px;
        width: 20px;
        min-height: 20px;
        height: 20px;
        place-self: center;
        background: url("../website/medias/icons/info_icon_popup.png") no-repeat center;
        background-size: contain;
        margin-right: 8px;
    }
}

header {
    .item-box-content > div:last-child {
        .caret-container,
        .btn-menu-item-link {
            border-bottom: 1px solid transparent !important;
			transition: border-color 100ms ease-in-out;
        }
    }

    .accordeon {
        overflow-y: hidden;
        transition: max-height 0.5s ease-in-out;
        max-height: 0;
        flex-basis: 100%;

        .btn-menu-item-link {
            border-bottom: none;
            padding-bottom: 0;

            &:last-child {
                padding-bottom: 15px;
            }
        }

		> .btn-box {
            display: flex;
            flex-grow: 10;

			&:first-child{
				padding-top: 5px;
			}
        }
    }

    .accordeon-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > .btn-box {
            display: flex;
            flex-grow: 10;
        }

        .btn{
            min-height: unset;
        }

		&.opened {
			& > div:first-child,
			& > div:nth-child(2){
				border-color: $color-grenke-grey-light !important;
			}
		}
    }

    .btn-box .caret-container {
        border-bottom: none;
        padding: 5px 0;

        @include media-breakpoint-up(md) {
            padding: 12px 0;
        }
    }

    .caret-container {
        display: flex;
        align-self: center;
        justify-content: center;
        margin-left: auto;
        padding: 5px 0;
        width: 46px;
        border-bottom: 1px solid $color-grenke-grey-light;
        cursor: pointer;

        svg {
            transition: transform 0.3s ease-in-out;
            transform: rotate(90deg);
            vertical-align: unset;
            fill: $color-grenke-blue;
        }

        @include media-breakpoint-up(md) {
            padding: 16px 0;
        }
    }

    .btn-box:hover .copy {
        font-family: Helvetica-regular, Verdana;
    }

    .opened {
        &.accordeon {
            max-height: 300px;
        }

        svg {
            transform: rotate(-90deg);
        }
    }

    .mobile.dropdown-navigation .item-box-content {
        .accordeon .btn-box {
            &:first-child {
                margin: 5px 0;
            }
            &:last-child {
                margin: 0 0 16px;
            }
        }

        .caret-container {
            border-bottom: none;
        }
    }

	.mobile.dropdown-navigation .accordeon-container.opened {
		& > div:first-child,
		& > div:nth-child(2){
			border-color: transparent !important;
		}
	}
}
