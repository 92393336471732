.sticky {
  position: fixed;
  right: 0;
  top: 80px; //15vh;
  z-index: 900;
  @include media-breakpoint-up(sm) {
    top: 33vh;
  }
  .sticky-container {
    background-color: $color-grenke-blue;
    height: 55px;
    width: 55px;
    position: relative;
    .sticky-hover {
      right: -265px;
      top: 0;
      width: 265px;
      height: 55px;
      background-color: $color-grenke-blue;
      color: $color-grenke-white;
      position: absolute;
      padding: 16px 0 16px 20px;
      transition: right 0.5s;
      &.mail {
        span {
          position: relative;
          color: $color-grenke-white;
          &:after {
            width: 7px;
            height: 12px;
            position: absolute;
            content: "";
            top: 4px;
            right: -16px;
            background-image: url("../website/medias/icons/arrow_white.png");
          }
        }
      }
    }
    &:hover {
      cursor: pointer;
      @include media-breakpoint-up(md) {
        .sticky-hover {
          right: 0;
        }
        @media (hover: none) {
          .sticky-hover {
            right: -265px;
          }
        }
      }
    }
    &.open {
      &:hover {
        cursor: pointer;
        @include media-breakpoint-up(md) {
          .sticky-hover {
            right: -265px;
          }
        }
      }
    }
    .icon-container {
      width: 55px;
      height: 55px;
      background-size: 20px;
      background-repeat: no-repeat;
      position: absolute;
      background-position: center;
      z-index: 9;
      &.phone {
        background-image: url("../website/medias/icons/sticky_phone@2x.png");
        background-color: $color-grenke-blue;
        z-index: 99;
        &.inactive {
          display: none;
        }
      }
      &.mail {
        background-image: url("../website/medias/icons/sticky_mail@2x.png");
        background-color: $color-grenke-blue;
      }

      &.help {
        background-image: url("../website/medias/icons/sticky_help@2x.png");
        background-color: $color-grenke-blue;
      }
      &.close-btn {
        background-image: url("../website/medias/icons/sticky_close@2x.png");
        background-color: $color-grenke-blue;
        background-size: 15px;
        display: none;
        z-index: 99;
        &.active {
          display: block;
        }
      }
    }
    &.mail,
    &.help {
      margin-top: 5px;
    }
  }
  .sticky-info-container {
    background-color: $color-grenke-grey-warm;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
    padding: 20px 75px 20px 20px;
    width: 330px;
    height: auto;
    position: absolute;
    top: 0;
    right: -340px;
    z-index: 10;
    transition: right 0.5s;
    .sticky-info-wrapper {
      max-height: 75vh;
      overflow-y: auto;
      @include media-breakpoint-up(md) {
        max-height: 50vh;
        overflow-y: auto;
      }
    }
    .sticky-phone-number {
      margin: 0 0 10px;
    }
    .sticky-headline + .sticky-phone-number {
      margin-top: 20px;
    }
    .sticky-headline + .sticky-phone-description {
      margin-top: 20px;
    }
    .sticky-phone-description + .sticky-phone-number {
      margin-top: 10px;
    }
    .sticky-opening-hours + .sticky-phone-description,
    .sticky-phone-number + .sticky-phone-description {
      margin-top: 20px;
    }
    &.active {
      right: 0;
    }
  }
}

header:has(.notification-banner) ~ main {
  .sticky {
    top: 200px;
    @include media-breakpoint-up(sm) {
      top: 33vh;
    }
  }
}
