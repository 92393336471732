/*
	Styles for iframe
*/
.iframe-module {
    @include std-bottom-margin(true);

    iframe {
        border: 0;
        width: 1px;
        min-width: 100%;
    }
}