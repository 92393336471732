// styles for hr_HR

#contact-detail-page {
    #contact-detail-form[data-language="hr_HR"] {
        #requestInterest-col {
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Consulting"],
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                display: block;
            }
        }
        [data-form-input-name="TaxID"] {
            display: block;
            margin-top: 20px;

            @include media-breakpoint-up(sm) {
                margin-top: 30px;
            }
        }

        &[data-form-interest="Career"],
        &[data-form-interest="Other"],
        &[data-form-interest="Press"] {
            [data-form-input-name="TaxID"] {
                display: none;
            }
        }

        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                display: block;
                [data-dropdown-value="Dealer_Sales_Support"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"]{
                    display: block;
                }
            }
            .row-customer-service {
                display: flex;
            }
            [data-customer-service-item="ContractNumber"] {
                display: block;
            }
        }

        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }


        #imprint-grenke-ag, .imprint-row, .phone-contact-number-grenke-ag {
            display: block;
        }

        [data-form-input-name="VATNumber"] {
            display: block;
            margin-top: 20px;
            @include media-breakpoint-up(sm) {
                margin-top: 30px;
            }
        }

        #optin-dataprotectionagreement {
            display: block;
        }
    }
}