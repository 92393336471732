// styles for en_LV & lv_LV
#contact-detail-page {
    #contact-detail-form[data-language="en_LV"],
    #contact-detail-form[data-language="lv_LV"]
    {
        // show interests-values
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                display: block;
            }
        }
        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }
        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"] {
                    display: block;
                }
            }
        }
        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                [data-dropdown-value="Dealer_Sales_Support"] {
                    display: block;
                }
            }
        }
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"]{
                    display: block;
                }
            }
            .row-customer-service {
                display: flex;
            }
            [data-customer-service-item="ContractNumber"] {
                display: block;
            }
        }

        #imprint-grenke-ag, .imprint-row, .phone-contact-number-grenke-ag {
            display: block;
        }
        
        &[data-form-topic="Leasing_And_Rental"] {
            .upload-row {
                display: flex;
            }
            [data-upload-title="supplier"], [data-upload-btn="supplier"] {
                display: block;
            }
        }

        [data-form-input-name="PostalCode"] {
            display: none;
        }

        [data-form-input-name="CompanyRegistrationNo"] {
            display: block;
            margin-top: 20px;
            @include media-breakpoint-up(sm) {
                margin-top: 0px;
            }
        }
    }
}

