.swiper-container-horizontal {
    overflow: hidden;
    .portfolio-module &.tab-container-navi {
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 30px;
        }
    }
    .tab-text-image-module &.tab-container-navi {
        margin-bottom: 17px;
        @include media-breakpoint-up(xl) {
            margin-bottom: 27px;
        }
    }
}

.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;

    .portfolio-module .tab-container-portfolio &, .tab-text-image-module .tab-container-tab-image & {
        opacity: 0 !important;

        &.swiper-slide-active {
            opacity: 1 !important;
        }
    }

    .portfolio-module .tab-container-navi &, .tab-text-image-module .tab-container-navi & {
        padding: 0 12px 10px;
        width: auto;

        .tab-item {
            padding: 10px 25px;
            position: relative;
            border: 1px solid transparent;
            width: 100%;
            cursor: pointer;
            color: $color-grenke-grey-dark;
            transition: all .3s;

            &:hover {
                color: $color-grenke-blue;
            }

            &:before {
                content: "";
                position: absolute;
                margin: auto;
                top: 100%;
                left: 0;
                right: 0;
                width: 10px;
                height: 10px;
                margin-top: -4px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                border-right: 1px solid $color-grenke-white-light;
                border-bottom: 1px solid $color-grenke-white-light;
                transition: all .3s;
            }

            &:after {
                content: "";
                position: absolute;
                margin: auto;
                top: 100%;
                left: 0;
                right: 0;
                width: 12px;
                height: 1px;
                border-top: 1px solid $color-grenke-white-light;
            }
        }

        &:last-child {
            margin-right: 0;
            padding: 0 0 10px 12px;
        }

        &:first-child {
            padding: 0 12px 10px 0;
        }

        &.active {
            .tab-item {
                color: $color-grenke-blue;
                border: 1px solid $color-grenke-grey-warm-icon;

                &:before {
                    border-right: 1px solid $color-grenke-grey-warm-icon;
                    border-bottom: 1px solid $color-grenke-grey-warm-icon;
                }

                &:hover {
                    border-color: $color-grenke-blue;

                    &:before {
                        border-right: 1px solid $color-grenke-blue;
                        border-bottom: 1px solid $color-grenke-blue;
                    }
                }
            }
        }
    }
    .tab-text-image-module .tab-container-navi & {
        @include media-breakpoint-up(xl) {
            padding: 1px 1px 7px 0;
        }
    }
}
.swiper-button-prev, .swiper-button-next {
    position: absolute;
    z-index: 2;
    top: 8px;
    margin: 0;
    background: none;
    align-items: center;
    display: flex;
    cursor: pointer;
    width: 32px;
    height: 32px;
    &:focus, &:active {
        outline: none;
    }
    &:after {
        display: none;
    }
}

.swiper-button-prev {
    left: 15px;
    background-image: linear-gradient(-90deg, rgba(253,253,253,0.00) 0%, #fbfafa 100%, $color-grenke-grey-background 96%);
}

.swiper-button-next {
    right: 15px;
    background-image: linear-gradient(90deg, rgba(253,253,253,0.00) 0%, #fbfafa 100%, $color-grenke-grey-background 96%);
}

.swiper-button-disabled {
    display: none !important;
}

.tab-text-image-module .tab-container-navi {
    .tab-text-image-icon {
        background: url("../website/medias/icons/sliderarrow@2x.png") center no-repeat;
        background-size: contain;
        width: 32px;
        height: 32px;
    }
    .tab-text-image-icon.next {
        transform: rotate(180deg);
    }
}
