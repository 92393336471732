#calculator {
    @include std-bottom-margin(false);
    .calculator-image-container {
        height: 105px;
        @include media-breakpoint-up(sm) {
            height: 160px;
        }
        @include media-breakpoint-up(md) {
            height: 215px;
        }
        @include media-breakpoint-up(lg) {
            height: 285px;
        }
        @include media-breakpoint-up(xl) {
            height: 490px;
        }
        .calculator-image-wrapper {
            overflow: hidden;
        }
    }
    .calculator-content {
        margin-top: -10px;
        @include media-breakpoint-up(sm) {
            margin-top: -35px;
        }
        @include media-breakpoint-up(md) {
            margin-top: -55px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: -50px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: -180px;
        }
        .calculator-info {
            background-color: $color-grenke-grey-footer;
            .calculator-info-container {
                padding: 20px 65px 20px 30px;
                @include media-breakpoint-up(sm) {
                    padding: 25px 140px 25px 50px;
                }
                @include media-breakpoint-up(md) {
                    padding: 30px 190px 30px 60px;
                }
                @include media-breakpoint-up(lg) {
                    padding: 50px 40px;
                }
                @include media-breakpoint-up(xl) {
                    padding: 60px 50px;
                }
                .calculator-info-wrapper {
                    flex-direction: column;
                    .calculator-info-headline {
                        p {
                            color: $color-grenke-blue;
                        }
                        &:after {
                            border: solid transparent;
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            content: "";
                            z-index: 1;
                            border-top-color: $color-grenke-grey-footer;
                            left: 50%;
                            bottom: -32px;
                            border-width: 16px;
                            margin-top: -30px;
                            @include media-breakpoint-up(lg) {
                                top: 50%;
                                transform: translateY(-50%);
                                bottom: 50%;
                                border-width: 30px;
                                margin-top: 0;
                                border-left-color: $color-grenke-grey-footer;
                                border-top-color: transparent;
                                left: 100%;
                                margin-left: 40px;
                            }
                            @include media-breakpoint-up(xl) {
                                margin-left: 50px;
                            }
                        }
                        * {
                            z-index: 1;
                            position: relative;
                        }
                    }
                    .calculator-info-badge {
                        position: absolute;
                        top: -50%;
                        right: 0;
                        height: 110px;
                        width: 110px;
                        @include media-breakpoint-up(sm) {
                            height: 160px;
                            width: 160px;
                        }
                        @include media-breakpoint-up(lg) {
                            position: relative;
                            top: auto;
                            right: auto;
                            height: 200px;
                            width: 200px;
                        }
                        @include media-breakpoint-up(xl) {
                            height: 245px;
                            width: 245px;
                        }
                    }
                }
            }
        }
        .calculator-form {
            background-color: $color-grenke-blue;
            #calculator-form {
                padding: 35px 30px 30px 30px;
                .calculator-field-description {
                    color: $color-grenke-white;
                    font-family: $font-family-regular;
                }
                @include media-breakpoint-up(sm) {
                    padding: 45px 45px 30px 45px;
                }
                @include media-breakpoint-up(md) {
                    padding: 50px 60px;
                }
                @include media-breakpoint-up(xl) {
                    padding: 60px 120px;
                }
                .zip-mobile-top-margin {
                    @include media-breakpoint-only(xs) {
                        margin-top: 20px;
                    }
                }
                input[type=number] {
                    -moz-appearance: textfield;
                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }
        }
    }
    #DisclaimerFoerdergutschein-error {
        top: auto;
        bottom: -10px;
        display: block;
        @include media-breakpoint-up(sm) {
            bottom: -25px;
        }
    }
}