footer {
    .back-to-top-container {
        padding-top: 0;
        padding-bottom: 40px;
        .back-to-top {
            caret-color: transparent !important;
            width: 48px;
            height: 48px;
            background-color: $color-grenke-grey-warm;
            background-image: url("../website/medias/icons/arrow_scrolltotop@2x.png");
            background-size: 13px 7px;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            @include transition(all, 0.5s);
            &:hover {
                box-shadow: 0 6px 6px 0 rgba(0,0,0,.26), 0 10px 20px 0 rgba(0,0,0,.19);
            }
        }
    }
    .footer-item-link, .footer-copyright {
        font-size: 14px;
        line-height: 18px;
        color: $color-grenke-grey-dark;
    }
    .footer-contact {
        background-color: $color-grenke-grey-footer;
        padding: 30px 0;
        @include media-breakpoint-up(lg) {
            padding: 40px 0;
        }
        .footer-contact-headline + .footer-contact-button {
            margin-top: 10px;
            @include media-breakpoint-up(sm) {
                margin-top: 30px;
            }
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }
    }
    .footer-social-media {
        padding-top: 20px;
        background-color: $color-grenke-grey-warm-60;
        .social-media-icon {
            padding: 0 25px;
            width: 38px;
            height: 38px;
            display: inline-block;
            margin: 20px 20px 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            @include media-breakpoint-up(sm) {
                padding: 0 40px;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .footer-social-media-spacer {
            padding-top: 40px;
            .spacer {
                width: 100%;
                height: 1px;
                background-color: $color-grenke-grey-light;
            }
        }
    }
    .footer-newsletter {
        padding-top: 40px;
        background-color: $color-grenke-grey-warm-60;
        .input-col + .input-col {
            margin-top: 10px;
            @include media-breakpoint-up(sm) {
                margin-top: 0;
            }
        }
        .input-col + .email-col {
            margin-top: 10px;
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }
        .input-col + .submit-col {
            margin-top: 30px;
            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }
        }
        .footer-newsletter-spacer {
            padding-top: 40px;
            .spacer {
                width: 100%;
                height: 1px;
                background-color: $color-grenke-grey-light;
            }
        }
    }
    .footer-navigation {
        padding: 40px 0;
        background-color: $color-grenke-grey-warm-60;
        .footer-navigation-col {
            .footer-navigation-item {
                padding-top: 4px;
                padding-bottom: 4px;
            }
            &:nth-child(n+3) {
                margin-top: 60px;
                @include media-breakpoint-up(sm) {
                    margin-top: 50px;
                }
                @include media-breakpoint-up(lg) {
                    margin-top: 0px;
                }
            }
        }
        .footer-navigation-headline + .footer-navigation-item {
            margin-top: 3px;
        }
    }
    .footer-legal {
        background-color: $color-grenke-grey-warm-60;
        .footer-leagl-spacer {
            width: 100%;
            height: 1px;
            background-color: $color-grenke-grey-light;
        }
        .footer-legal-text {
            padding: 40px 0;
        }
    }
    .footer-meta {
        padding-top: 30px;
        background-color: $color-grenke-white;
        .footer-meta-col-spacer + .footer-meta-col-spacer {
            margin-top: 25px;
            @include media-breakpoint-up(sm) {
                margin-top: 0;
            }
            &.campaign {
                @include media-breakpoint-only(sm) {
                    margin-top: 25px;
                }
            }
        }
        .footer-meta-col {
            padding: 0 10px;
        }
        .footer-meta-spacer {
            padding-top: 30px;
            margin-bottom: 25px;
            .spacer {
                width: 100%;
                height: 1px;
                background-color: $color-grenke-grey-light;
            }
            @include media-breakpoint-up(md) {
                padding-top: 0;
                margin-bottom: 0;
                .spacer {
                    width: 1px;
                    height: 18px;
                }
            }
        }
        .footer-meta-copyright {
            margin-bottom: 25px;
        }
        .country-switch {
            cursor: pointer;
            margin-bottom: 30px;
            width: 100%;
            text-align: center;
            line-height: 1;
            @include media-breakpoint-up(sm) {
                margin-bottom: 40px;
            }
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                width: auto;
                text-align: left;
            }
            .flag {
                width: 20px;
                height: 15px;
                margin: 0 10px 1px 0;
            }
        }
        .meta-nav-wrapper {
            width: 100%;
            @include media-breakpoint-up(md) {
                width: auto;
            }
        }
    }
    //.footer-content {
    //    background-color: $color-grenke-grey-warm;
    //    .footer-navigation {
    //        padding: 50px 0;
    //        border-bottom: solid 2px $color-grenke-grey-dark;
    //        .footer-navigation-headline {
    //            margin-bottom: 20px;
    //        }
    //        .footer-navigation-link {
    //            margin-bottom: 10px;
    //            &:last-child {
    //                margin-bottom: 0;
    //            }
    //        }
    //        .footer-navigation-sozial-icons img {
    //            margin-bottom: 10px;
    //        }
    //        .footer-navigation-seal {
    //            width: 100%;
    //            margin-bottom: 10px;
    //            &:last-child {
    //                margin-bottom: 0;
    //            }
    //        }
    //    }
    //    .footer-newsletter {
    //        padding: 50px 0;
    //        border-bottom: solid 2px $color-grenke-grey-dark;
    //        .footer-newsletter-input {
    //            padding: 10px;
    //            border: none;
    //            width: 100%;
    //            &:focus {
    //                outline: none;
    //            }
    //        }
    //    }
    //    .footer-end {
    //        padding: 50px 0;
    //        .footer-end-links a {
    //            margin-right: 10px;
    //            &:last-child {
    //                margin-right: 0;
    //            }
    //        }
    //    }
    //}
}
