// styles for es_ES

#contact-detail-page {
    #contact-detail-form[data-language="es_ES"] {
        // show interests-values
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"] {
            #requestTopic-col {
                display: block;
            }
        }

        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                display: block;
            }

            #requestTopic-col {
                [data-dropdown-value="Dealer_Sales_Support"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"] {
                    display: block;
                }
            }

            &[data-form-topic="Leasing_And_Rental"] {
                .upload-row {
                    display: flex;
                    [data-upload-title="supplier"], [data-upload-btn="supplier"] {
                        display: block;
                    }
                }
            }
        }

        #phone-time-wrapper-js {
            .time-slot {
                display: block;
                max-width: 100%;
                flex: 0 0 100%;
            }
            .time-slot+.time-slot{
                margin-top: 20px;
            }
        }

        #imprint-grenke-ag, .imprint-row, .phone-contact-number-grenke-leasing {
            display: block;
        }

        #optin-dataprotectionagreement {
            display: block;
        }
    }
    #contact-detail-form[data-language="es_CL"] {
        [data-form-input-name="TaxID"] {
            display: block;
            margin-top: 20px;

            @include media-breakpoint-up(sm) {
                margin-top: 30px;
            }
        }

        &[data-form-interest="Career"],
        &[data-form-interest="Other"],
        &[data-form-interest="Press"] {
            [data-form-input-name="TaxID"] {
                display: none;
            }
        }
    }
}