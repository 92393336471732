p {
    &.fgs-result-info-content {
        font-family: 'Helvetica';
        color: $color-grenke-white;
        letter-spacing: 0;
        text-align: center;

        line-height: 28px;
        font-size: 36px;
        @include media-breakpoint-up(lg) {
            font-size: 40px;
        }
        @include media-breakpoint-up(xl) {
            line-height: 62px;
            font-size: 62px;
        }
        &.thueringen {
            line-height: 28px;
            font-size: 29px; // auf 29px?
            @include media-breakpoint-up(xl) {
                line-height: 36px;
                font-size: 43px;
            }
        }
        &.currency {
            font-size: 29px;
            @include media-breakpoint-up(xl) {
                font-size: 62px;
            }
        }
    }
    &.fgs-result-info-description {
        font-family: 'Helvetica';
        color: $color-grenke-white;
        letter-spacing: 0;
        text-align: center;
        line-height: 23px;
        font-size: 16px;
        @include media-breakpoint-up(xl) {
            line-height: 28px;
        }
        &.percent{
            line-height: 27px;
            @include media-breakpoint-up(xl) {
                line-height: 28px;
            }
        }
    }
}

h3 {
    &.fgs-result-info-headline {
        color: $color-grenke-white;
    }
}

h4 {
    &.fgs-result-form-headline {
        color: $color-grenke-grey-dark;
    }
}

.copy-1 {
    &.fgs-result-form-copy {
        color: $color-grenke-grey-dark;
    }
}