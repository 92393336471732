$font-family-bold: "HelveticaNeueLTW02-85Heavy", Arial, sans-serif;
$font-family-italic: "HelveticaNeueLTW02-86HvIt", Arial, sans-serif;
$font-family-regular: "HelveticaNeueLTW02-55Roman", Arial, sans-serif;

.explorer-module {
  .tb-copy {
    margin: 20px 0;
    font-family: $font-family-regular;
    font-size: 16px;
    color: $black;
    letter-spacing: 0.27px;
    line-height: 26px;
  }

  &.large {
    background: rgba(64, 99, 126, 0.3);
    overflow: visible;
    padding-top: 50px;

    @include std-bottom-margin(false, 270px);

    @include media-breakpoint-only(sm) {
      @include std-bottom-margin(false, 365px);
    }

    @include media-breakpoint-only(md) {
      @include std-bottom-margin(false, 110px);
    }
    @include media-breakpoint-up(lg) {
      max-height: 384px;
      margin-top: calc(100px + 43px);
      @include std-bottom-margin(false, 43px);
    }
    @include media-breakpoint-only(xl) {
      margin-top: calc(130px + 113px);
      @include std-bottom-margin(false, 113px);
    }

    .content {
      padding-bottom: 100px;

      @include media-breakpoint-only(sm) {
        padding-bottom: 125px;
      }
      @include media-breakpoint-up(md) {
        padding-bottom: 0;
      }

      .tb-copy {
        overflow: hidden;

        @include media-breakpoint-up(md) {
          max-height: 182px;
        }
        @include media-breakpoint-up(lg) {
          max-height: 130px;
        }
        @include media-breakpoint-up(xl) {
          max-height: 104px;
        }
      }
    }

    .btn-tb-primary {
      @include media-breakpoint-only(lg) {
        width: 78%;
      }
      @include media-breakpoint-only(xl) {
        width: 57.6%;
      }
    }

    .logo-md {
      @include media-breakpoint-only(lg) {
        height: 65px;
        width: auto;
      }
      @include media-breakpoint-only(xl) {
        height: 90px;
        width: auto;
      }

      img {
        width: 100%;

        @include media-breakpoint-up(lg) {
          position: absolute;
          height: 100%;
          width: auto;
        }
      }
    }

    .image-wrapper {
      overflow: hidden;
      position: absolute;
      width: calc(100% - 30px);
      height: 370px;
      top: calc(100% - 100px);

      @include media-breakpoint-only(sm) {
        height: 490px;
        top: calc(100% - 125px);
      }

      @include media-breakpoint-only(md) {
        height: 390px;
        top: 0;
        margin-top: 60px;
      }

      @include media-breakpoint-only(lg) {
        height: 452px;
        top: -156px;
      }

      @include media-breakpoint-only(xl) {
        height: 610px;
        top: -263px;
      }

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  &.small {
    @include std-bottom-margin(false);

    .image-wrapper {
      position: relative;
      overflow: hidden;
      height: 350px;

      @include media-breakpoint-only(sm) {
        height: 495px;
      }
      @include media-breakpoint-only(md) {
        height: 532px;
      }
      @include media-breakpoint-only(lg) {
        height: 433px;
      }

      @include media-breakpoint-only(xl) {
        height: 610px;
      }

      img {
        width: auto;
        height: 100%;
      }
    }

    .content-wrapper {
      margin-top: -25px;

      @include media-breakpoint-only(sm) {
        margin-top: -40px;
      }
      @include media-breakpoint-only(md) {
        margin-top: -140px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: -363px;
      }
      @include media-breakpoint-up(xl) {
        margin-top: -540px;
      }

      .infobox-title-wrapper {
        position: relative;
        width: 100%;

        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 30px;
        }

        &:after {
          content: "";
          background: var(--flag-light);
          position: absolute;
          width: 100%;
          left: 0;

          height: calc(50% + 20px);
          bottom: -20px;

          @include media-breakpoint-up(md) {
            height: calc(50% + 30px);
            bottom: -30px;
          }
        }

        .infobox-title {
          font-family: $font-family-italic;
          letter-spacing: 0;
          line-height: 26px;
          color: $white;
          text-transform: uppercase;
          margin-bottom: 0;
          padding: 8px;
          z-index: 3;
          position: relative;
          display: table;
          background: var(--flag);
          overflow: hidden;

          max-width: calc(100% - 20px - 8.8%);
          font-size: 18px;
          left: 20px;

          @include media-breakpoint-up(lg) {
            font-size: 22px;
            left: 30px;
          }

          @include media-breakpoint-only(sm) {
            max-width: calc(100% - 30px - 75px);
          }
          @include media-breakpoint-between(md, lg) {
            max-width: calc(100% - 60px);
          }
          @include media-breakpoint-only(xl) {
            max-width: calc(100% - 30px - 200px);
          }
        }
      }

      &.add-padding {
        padding-top: 30px;
      }

      .content {
        margin-bottom: 20px;
        padding: 0 20px 30px;
        background: var(--flag-light);

        @include media-breakpoint-up(sm) {
          padding: 0 30px 30px;
        }
        @include media-breakpoint-up(lg) {
          padding: 0 30px 40px;
        }

        &.add-padding {
          padding-top: 30px;
        }

        .headline {
          font-family: $font-family-bold;
          color: $white;
          letter-spacing: 0;

          font-size: 28px;
          line-height: 36px;
          @include media-breakpoint-up(lg) {
            font-size: 40px;
            line-height: 50px;
          }
        }

        .tb-copy {
          color: $white;
          margin-bottom: 0;
        }
      }
    }
  }

  .tb-btn {
    font-family: $font-family-italic;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background-color: transparent;

    &:hover,
    &:active,
    &:focus,
    &:visited {
      box-shadow: none;
      outline: none;
    }

    &.btn-tb-primary {
      margin-bottom: 40px;

      @include media-breakpoint-only(sm) {
        margin-bottom: 60px;
      }
      @include media-breakpoint-up(md) {
        margin-bottom: 50px;
      }

      .btn.button {
        position: relative;
        box-sizing: border-box;
        border-radius: 34px;
        white-space: normal;
        width: 100%;
        min-height: 48px;
        padding: 8px 30px 8px 30px;
        background-color: $black;
        border: none;

        transition: transform 0.3s ease-in-out;
        -webkit-transition: transform 0.3s ease-in-out;

        &:hover,
        &:focus {
          transform: scale(1.04);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
          transition: transform 0.3s ease-in-out;
          -webkit-transition: transform 0.3s ease-in-out;
        }

        .btn-text {
          color: $white;
        }
      }
    }

    &.secondary-btn {
      transition: all 0.5s ease-in-out;
      padding-right: 10px;

      &:hover,
      &:focus {
        transition: all 0.5s ease-in-out;
        padding-right: 0;
      }

      @include media-breakpoint-only(xs) {
        max-width: 250px;
        text-align: end;
        margin-left: auto;
      }

      .icon {
        position: relative;
        transition: all 0.3s ease-in-out;
        height: 26px;
        width: 20px;

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .btn-text {
        color: $color-grenke-grey-dark;
      }
    }

    .btn-text {
      font-family: $font-family-italic;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 0;
      text-align: center;
      line-height: 26px;
    }

    .btn-text + .icon,
    .icon + .btn-text {
      margin-left: 10px;
    }
  }
}
