$slider-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAYAAABe3VzdAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5AwPFBQRg6PyaAAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAACrklEQVRYw+3Xzy+bcRwH8PfztCKUapsWqQlG/FgTSccSiUpnFxbBDmgs0bIlolzoD0fVutGqixAHWStLmB6EWHoQiWEcSDOJGBccVGjTUsok8zz7B7Y9SrvI0s/1+/nk+zp8nveTL/HyfQ+NR1wkHnlFgVFgFBgJYAybjb6ORkiy0xl7Jdnp6OtoRAyb/W+AbBYLpnYFZNJ8tNSWM/a31JZDJs2HqV0BNosVWSBJkjC01aOkMBe7B24YRqYYZwwjU9g9cKOkMBeGtnqQJBkZIEmS6Gmtg0xagP2jE3QPTSB4fcM4F7y+QffQBPaPTiCTFqCntS4k5J06CYKAXlUDebEEh24PNGYbApdXd74kcHkFjdmGQ7cH8mIJ9KoaEAQRPqBWWY3KUincHh90VjvOLoIh79LZRRA6qx1ujw+VpVJoldXhAaobKlBVVoRT3zm0Fhu8/sC9I8PrD0BrseHUd46qsiKoGyoYZ1iZz+W9fzp89+YV3r4ug/csgM6BDzj2+B+ca5dXP/D12y7kRc/wQpIDFknC9X3/Pw3q8ZlFOBbWIORxYdEokSxIevCFyYIkWDRKCHlcOBbWMD6z+LAdHJ50Yn55E6lCPqz6Zgj53HvjhHwurPpmpAr5mF/exPCkMzxfscU+B+eqC2KRAOYuJXiJnJBxvEQOzF1KiEUCOFddsNjnwhczNE1jwDaLpY1tZIhFGNSpwE2IvzOOmxCPQZ0KGWIRlja2MWCbBU3T4QMCAEVRMI05sOLaQVZaCvo7m8CJi2Wc48TFor+zCVlpKVhx7cA05gBFUZH5F1MUBePoNNa39pCXKYZRrWCcMaoVyMsUY31rD8bR6ZBwjDn4WyRN48vmDp4+ScHHz8s49Z3/tf/Y6wc3IR69I5/w8/Y25N0los/OKDAKjAIjW78AwAHiCXTENrMAAAAASUVORK5CYII=");

.cfo-calculator, .leasing-calculator {
  background-color: $color-grenke-grey-warm;
  width: 100%;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;

  h3 {
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .input-end-label {
    right: 0;
    transform: translateY(-50%) translateX(-27px);
    pointer-events: none;
  }

  .formRow--input.js-input {
    text-align: left;
  }

  &.currency-GBP, &.currency-CHF {
    .input-end-label {
      left: 0;
      right: unset;
      transform: translateY(-50%) translateX(38px);
    }

    .formRow--input.js-input {
      text-align: right;
    }
  }

  &.currency-EUR {
    .input-end-label {
      right: 0;
      transform: translateY(-50%) translateX(-27px);
    }

    .formRow--input.js-input {
      text-align: left;
    }
  }

  .row {
    // padding-right: 40px;
    // padding-left: 40px;
    // padding-bottom: 20px;

    & > div {
      align-self: center;
      // padding-left: 30px;

      @include media-breakpoint-up(xl) {
        // padding-left: 40px;
      }
    }

    .float-right {
      text-align: right;
      margin-left: auto !important;

      p.date-hero {
        text-align: right;
      }

      @include media-breakpoint-up(md) {
        padding-left: 30px;
      }
    }
  }

  .row.wrapper-blue {
    margin: 0 -30px;

    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    background-color: $color-grenke-blue;
    color: white;
    padding-top: 10px;
    padding-bottom: 20px;

    @include media-breakpoint-up(md) {
      padding-bottom: 40px;
      padding-top: 20px;
      padding-bottom: 30px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 -40px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 17px solid $color-grenke-grey-warm;
      margin-top: -17px;
      z-index: 1;
      transform: rotate(90deg) translateX(50%) translateY(50%);
    }

    .row.no-gutters {
      align-items: center;
    }

    p {
      color: white;
    }

    .modal-content {
      p {
        color: $color-grenke-grey-dark;
      }
    }
  }

  .input-end-label {
    position: absolute;
    top: 21px;
    color: $color-grenke-grey-light;
    font-size: 16px;
  }

  .formRow--input.js-input {
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
  }

  .copytext-box {
    margin-top: 10px;

    @include media-breakpoint-up(xl) {
      margin-top: 20px;
    }

    // margin-bottom: 15px;

    .regular {
      color: #212529;
      margin-top: 10px;
    }
  }

  .input-container {
    @include media-breakpoint-up(md) {
      padding-left: 20px;
    }
  }

  .range-container {
    min-height: 40px;
    padding-right: 20px;

    .range-inner-container {
      position: relative;
      height: 31px;
      margin: 5px 0;

      .rangeBg {
        background: $color-grenke-grey-light;
        content: "";
        position: absolute;
        top: 13px;
        width: 100%;
        height: 5px;
      }

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        background: linear-gradient($color-grenke-blue, $color-grenke-blue) no-repeat;
        background-position-x: 100px;
        outline: none;
        position: absolute;
        top: 13px;
        z-index: 2;
        padding: 0;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 40px;
          height: 30px;
          background: $slider-image;
          cursor: pointer;
          border-radius: 0;
        }

        &::-moz-range-thumb {
          width: 40px;
          height: 30px;
          background: $slider-image;
          cursor: pointer;
          border-radius: 0;
          outline: none;
        }

        &::-ms-track {
          width: 100%;
          // height: 5px;
          background: transparent;
          border-color: transparent;
          border-width: 13px 0;
          color: transparent;
        }
        &::-ms-fill-lower {
          background: $color-grenke-blue;
          border-radius: 0;
        }
        &::-ms-fill-upper {
          background: $color-grenke-grey-light;
          border-radius: 0;
        }
        &::-ms-thumb {
          border: none;
          width: 40px;
          height: 30px;
          border-radius: 0;
          background: $slider-image;
        }
      }
    }
  }

  .info-icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}

// IE only
@media screen and (min-width: 0\0) {
  .cfo-calculator, .leasing-calculator{
    .range-container .range-inner-container {
      .slider {
        height: 31px !important;
        background: none !important;
        background-position-x: 0 !important;
        top: 0 !important;
      }
      .rangeBg {
        display: none !important;
      }
    }
  }
}
