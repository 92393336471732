.process-module {
    @include std-bottom-margin(false);
}

.process-module {
    .process-flag {
        position: relative;
        background-color: $color-grenke-grey-warm;
        padding: 10px;
        width: auto;
        margin: 0 auto 30px;
        text-align: center;
        @include media-breakpoint-up(lg) {
            margin: 0 auto 40px;
        }
        &:after {
            background-color: $color-grenke-grey-warm;
            bottom: -10px;
            content: '';
            position: absolute;
            left: 50%;
            width: 11px;
            height: 11px;
            transform: rotate(45deg) translate(-50%, 0);
        }
    }
    .process-content {
        text-align: center;
        position: relative;
        @include media-breakpoint-up(md) {
            min-height: 286px;
        }
        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            background: url("../website/medias/icons/arrow_process@2x.png") no-repeat center;
            background-size: contain;
            width: 30px;
            height: 286px;
            right: -30px;
        }
        @include media-breakpoint-up(md) {
             padding: 0 10px;
        }
        @include media-breakpoint-up(xl) {
            padding: 0 15px;
        }
        .img-box {
            height: 60px;
            width: 60px;
            margin: 0 auto 20px;
            @include media-breakpoint-up(sm) {
                margin: 0 auto 30px;
            }
            @include media-breakpoint-up(md) {
                height: 80px;
                width: 80px;
                margin: 0 auto 30px;
            }
            @include media-breakpoint-up(lg) {
                height: 90px;
                width: 90px;
                margin: 0 auto 40px;
            }
            img {
                height: 60px;

                @include media-breakpoint-up(md) {
                    height: 80px;
                }
                @include media-breakpoint-up(lg) {
                    height: 90px;
                }
            }
        }
        h4 {
            margin-bottom: 10px;
        }
    }
    .process-tile {
        margin-bottom: 45px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 60px;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
            .process-content {
                &:after {
                    display: none;
                }
            }
        }
    }
    .mobile {
        .process-content {
            &:after {
                display: none;
            }
        }
        //.button-box {
        //    padding: 0;
        //}
        .process-tile {
            position: relative;
            margin-bottom: 91px;
            @include media-breakpoint-up(sm) {
                margin-bottom: 104px;
            }
            &:after {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                content: '';
                background: url("../website/medias/icons/line_down@2x.png") no-repeat center;
                background-size: contain;
                width: 443px;
                height: 34px;
                bottom: -61px;
                @include media-breakpoint-up(sm) {
                    height: 47px;
                    bottom: -73px;
                }
            }
            &:last-child {
                margin-bottom: 0;
                &:after {
                    background: none;
                }
            }
        }
    }
}