.factoringtest-container {
  background-color: $color-grenke-grey-warm;
  min-height: 345px;
  padding: 30px 15px;
  @include std-bottom-margin(false);
  @include media-breakpoint-up(md) {
    padding: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding: 50px;
  }
  &.hook {
    background-image: url(../website/medias/icons/Fill4@2x.png);
    background-repeat: no-repeat;
    background-position: #{calc(100% / 12 * 2)} 25%;
    background-size: auto 45%;
    @include media-breakpoint-up(sm) {
      background-position: #{calc(100% / 12 * 2)} 20%;
      background-size: auto 50%;
    }
  }
  &.question-mark {
    background-color: transparent;
    background-image: linear-gradient(to bottom, rgba(252, 252, 252, 0.3) 0%, rgba(252, 252, 252, 0.3) 100%),
      url(../website/medias/icons/factoringtest_question@2x.png);
    background-position: #{calc(100% / 12)} 30px;
    background-repeat: no-repeat;
    background-size: auto 50%;

    @include media-breakpoint-up(md) {
      background-position: #{calc(100% / 12 * 2)} 40px;
    }
    @include media-breakpoint-up(xl) {
      background-position: #{calc(100% / 12 * 2)} 50px;
    }
  }

  .question-buttons {
    margin-top: 32px;
    .button {
      margin-bottom: 15px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
    }
    .btn-col + .btn-col {
      margin-top: 20px;
      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }
  }

  .answer-buttons {
    display: none;
    .btn-box {
      width: 100%;
    }
    .answer-button {
      display: none;
    }
  }
}

.question-slider {
  .count-box {
    font-size: 13px;
    line-height: 18px;
    color: $color-grenke-blue;
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    @include h3();
    & {
      padding: 0 60px 0 60px;
      text-align: center;
      color: $color-grenke-blue;
    }
  }

  .question-slide-item {
    min-height: 130px;
    @include media-breakpoint-up(sm) {
      min-height: 150px;
    }
    &.result-box {
      h2 {
        color: $color-grenke-blue;
        text-align: center;
      }
      h3 {
        color: $color-grenke-grey-dark;
        padding: 0 6%;
        text-align: center;
        @include media-breakpoint-up(sm) {
          padding: 0 20%;
        }
      }
      h2 + h3 {
        margin-top: 20px;
      }
    }
  }
  .result-item {
    display: none;
  }
}
