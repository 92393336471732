// styles for it_IT
#contact-detail-page {
    #contact-detail-form[data-language="it_IT"] {
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Cooperation"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Other"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Cooperation"],
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                display: block;
            }
        }

        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Liquidity_Startups"],
                [data-dropdown-value="Asset_Financing"],
                [data-dropdown-value="Liquidity_Selfemployed"],
                [data-dropdown-value="Liquidity_Institutions"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }

            &[data-form-topic="Leasing_And_Rental"] {
                .upload-row {
                    display: flex;
                }
                [data-upload-title="supplier"], [data-upload-btn="supplier"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Cooperation"] {
              #requestTopic-col {
                [data-dropdown-value="Dealer_Sales_Support"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"],
                [data-dropdown-value="Factoring"] {
                    display: block;
                }
            }

            .row-customer-service {
                display: flex;
            }

            [data-customer-service-item="CustomerNumber"],
            [data-customer-service-item="ContractNumber"] {
                display: block;
            }
        }

        #imprint-grenke-ag {
            display: block;
        }

        &[data-form-topic="Liquidity_Startups"],
        &[data-form-topic="Factoring"],
        &[data-form-topic="Dealer_Advanced_Financing"] {
            #imprint-grenke-ag {
                display: none;
            }

            #imprint-grenke-bank-ag {
                display: block;
            }
        }

        #phone-time-wrapper-js {
            .time-slot {
                display: block;
            }
        }

        .phone-contact-number {
            &.phone-contact-number-grenke-ag {
                display: block;
            }
        }

        &[data-form-topic="Liquidity_Startups"],
        &[data-form-interest="Offers"][data-form-topic="Factoring"] {
            .phone-contact-number-grenke-ag {
                display: none;
            }

            .phone-contact-number-grenke-bank-ag {
                display: block;
            }
        }

        &[data-form-interest="Customer_Service"][data-form-topic="Factoring"] {
            .phone-contact-number-grenke-ag {
                display: none;
            }

            .phone-contact-number-customer-service-factoring {
                display: block;
            }
        }

        &[data-form-topic="Dealer_Advanced_Financing"] {
            .phone-contact-number-grenke-ag {
                display: none;
            }

            .phone-contact-number-advanced-financing {
                display: block;
            }
        }

        &[data-form-interest="Career"],
        &[data-form-interest="Press"],
        &[data-form-interest="Other"] {
            .phone-contact-wrapper {
                display: none;
            }
            .phone-contact-number-grenke-ag {
                display: none;
            }
        }

        [data-form-input-name="PostalBox"],
        [data-form-input-name="VATNumber"],
        [data-form-input-name="City"] {
            display: block;
        }

        [data-form-input-name="PostalBox"],
        [data-form-input-name="City"],
        [data-form-input-name="VATNumber"] {
            margin-top: 30px;
            @include media-breakpoint-only(xs) {
                margin-top: 20px;
            }
        }
    }
}

