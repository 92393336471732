.modal {
  &.show {
    .modal-dialog {
      transform: translate(0, -50%);
    }
  }

  .modal-dialog {
    max-width: 843px;
    max-height: 677px;
    top: 50%;
    transform: translate(0, -50%);

    &.reset {
      transform: none;
      top: 0;
      margin: 0;

      .modal-content .content-wrapper *:last-child {
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
          margin-bottom: inherit;
        }
      }
    }

  }

  .modal-content {
    background-color: $color-grenke-grey-background;
    border-radius: 0;
    border: none;
    max-width: 843px;
    max-height: 677px;
    padding: 30px;
    overflow-y: auto;
    @include media-breakpoint-up(lg) {
      padding: 40px;
    }
    @include media-breakpoint-up(xl) {
      padding: 50px;
    }

    .modal-body {
      padding: 0;
    }

    .content-wrapper {
      max-height: 76vh; //577px;
      margin-bottom: 30px;

      p {
        text-align: left !important;
      }

      h4 {
        margin-bottom: 20px;
        text-align: left !important;
      }

      h5 {
        margin: 30px 0 15px;
      }

      li {
        &:nth-child(n+2) {
          margin-top: 10px;
        }
      }

      p + ul, p + .buttons {
        margin-top: 15px;
      }

      ul + p {
        margin-top: 15px;
      }

      .buttons {
        div + div {
          margin-top: 15px;
          @include media-breakpoint-up(lg) {
            margin-top: 0;
          }
        }
      }
    }

    .video-wrapper {
      margin-top: 30px;
    }

    .close {
      opacity: 1;

      &:focus {
        outline: none;
      }

    }

    &.info-modal {
      .modal-head {
        margin-bottom: 15px;
      }

      .info-icon {
        caret-color: transparent !important;
        width: 30px;
        height: 30px;
        background: url("../website/medias/icons/info_icon_popup.png") no-repeat center 100%;
        background-size: contain;
      }

      h4 {
        #contact-detail-form & {
          color: $color-grenke-blue;
        }
      }
    }
  }
}

.info-bubble {
  caret-color: transparent !important;
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: url("../website/medias/icons/info_icon_popup.png") no-repeat center 100%;
  background-size: cover;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }

  .blue-theme & {
    background: url("../website/medias/icons/info_icon_popup_white.png") no-repeat center 100%;
    background-size: cover;
    margin-left: 10px;
  }

  .solution-finder-module & {
    display: none;
  }
}

.play-btn {
  $size-play-btn: 148px;
  caret-color: transparent !important;
  width: $size-play-btn;
  height: $size-play-btn;
  background: url(../website/medias/icons/playbutton.png) no-repeat center 100%;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all .5s;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;

  &:hover {
    width: $size-play-btn*0.9;
    height: $size-play-btn*0.9;
  }
}

.popup-video {
  color: $color-grenke-blue;
  text-decoration: underline;
  cursor: pointer;
}

.youtube-iframe-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-consent-banner {
  width: 100%;
  float: left;
  padding: 0;
  display: initial;
  font-family: inherit;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  height: auto;
  min-height: 0;
  max-height: none;
  min-width: 0;
  max-width: none;
  border-radius: 0;
  border: none;
  clear: none;
  position: static;
  bottom: auto;
  overflow: visible;
  vertical-align: middle;
  visibility: visible;
  z-index: auto;
  box-shadow: none;

  .btn-wrapper {
    padding: 0 15px 10px 15px;
    margin-top: 15px;
    @include media-breakpoint-only(xs) {
      margin-top: 10px;
    }

    .video-consent-btn > span {
      letter-spacing: .05em;
      line-height: 1.4;
      font-size: .813em;
      color: $color-grenke-blue;
    }

    .video-consent-btn .btn-dark{
      border: 1px solid $color-grenke-blue !important;
    }
  }

  div.row {
    .consent-text {
      padding: 0 30px 10px;
      clear: both;
      width: 100%;

      p {
        font-family: Helvetica;
        font-size: 0.913em;
        font-weight: 400;
        color: $color-grenke-grey-dark;
        line-height: 1.5;
        text-decoration-style: solid;
      }
    }

    .consent-headline {
      hyphens: auto;
      font-style: normal;
      box-sizing: content-box;
      -webkit-font-smoothing: auto;
      letter-spacing: normal;
      margin: 0;
      left: auto;
      right: auto;
      top: auto;
      text-decoration: none;
      text-indent: 0;
      text-shadow: none;
      text-transform: uppercase;
      white-space: normal;
      background: none;
      overflow: visible;
      vertical-align: baseline;
      visibility: visible;
      z-index: auto;
      box-shadow: none;
      font-weight: 600;
      float: left;
      text-align: left;
      line-height: 1.4;
      padding: 0 0 10px 30px;
      width: calc(100% - 90px);
      color: $color-grenke-blue !important;
      font-family: 'Helvetica' !important;
      font-size: 1.1em !important;
    }

    .video-consent-btn {
      width: 100%;
    }
  }
}
