#event-overview-page {
  .filter-container {
    margin-bottom: 50px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 60px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 90px;
    }
  }
  .filter-reset {
    height: 100%;
    @include formfield-copy;
    color: $color-grenke-grey-dark-70;
    text-decoration: underline;
    cursor: pointer;
  }
  .col-filter-category,
  .col-filter-reset {
    margin-bottom: 30px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
  .col-filter-search-input {
    @include media-breakpoint-only(md) {
      margin-top: 30px;
    }
  }
  .no-documents-js {
    display: none;
    color: $color-grenke-grey-dark-70;
    @include std-bottom-margin(false);
  }
  .back-to-top-height {
    height: 0px;
    margin-top: 88px;
  }
}
