#countrySwitchModal {
    overflow-y: hidden;

    .modal-dialog {
        max-width: 540px;
        min-width: 0;
        max-height: 530px;
        @include media-breakpoint-up(md) {
            max-width: 680px;
            min-width: 680px;
            max-height: 650px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 905px;
            min-width: 905px;
            max-height: 710px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 1220px;
            min-width: 1220px;
            max-height: 630px;
        }
        .modal-content {
            max-width: 540px;
            min-width: 0;
            max-height: 530px;
            padding: 30px 40px;
            @include media-breakpoint-up(md) {
                max-width: 680px;
                min-width: 680px;
                max-height: 650px;
                padding: 40px;
            }
            @include media-breakpoint-up(lg) {
                max-width: 905px;
                min-width: 905px;
                max-height: calc(100vh - 3.5rem);
                padding: 30px;
            }
            @include media-breakpoint-up(xl) {
                max-width: 1220px;
                min-width: 1220px;
                max-height: calc(100vh - 3.5rem);
                padding: 50px;
            }
            .modal-header {
                display: block;
                border-bottom: none;
                padding: 0 0 20px 0;
                @include media-breakpoint-up(md) {
                    padding: 0 0 30px 0;
                }
                @include media-breakpoint-up(xl) {
                    padding: 0 0 40px 0;
                }
                .modal-title {
                    float: left;
                }
                .close {
                    float: right;
                }
            }
            .modal-body {
                overflow: auto;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    overflow: hidden;
                }
                .country-container {
                    column-count: 1;
                    column-fill: balance;
                    @include media-breakpoint-up(md) {
                        column-count: 2;
                        column-gap: 40px;
                    }
                    @include media-breakpoint-up(lg) {
                        column-count: 3;
                        column-gap: 30px;
                    }
                    @include media-breakpoint-up(xl) {
                        column-count: 4;
                    }
                    .column-item {
                        display: inline-block;
                        width: 100%;
                    }
                    .headline-container-international {
                        margin-bottom: 20px;
                        margin-top: 0;
                        @include media-breakpoint-up(md) {
                            margin-bottom: 11px;
                            margin-top: 10px;
                        }
                    }
                    .headline-container-contries {
                        margin-bottom: 16px;
                        @include media-breakpoint-up(md) {
                            margin-bottom: 14px;
                            margin-top: 7px;
                        }
                    }
                    .country-item {
                        padding: 10px 0;
                        border-bottom: solid 1px $color-grenke-country-switch-border;
                        .country-text-container {
                            .flag {
                                width: 20px;
                                height: 15px;
                                margin: 0 0 2px 5px;
                            }
                            .country {
                                margin-left: 10px;
                            }
                        }
                        .language {
                            margin-right: 5px;
                        }
                        &.international {
                            padding: 0 0 30px 0;
                            border-bottom: none;
                            @include media-breakpoint-up(md) {
                                padding: 10px 0 11px;
                            }
                        }
                    }
                }
            }
        }
    }
}
