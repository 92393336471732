p, address {
    margin: 0;
}

// fix modal for ie
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .modal-open .modal {
        overflow-y: hidden;
    }
    .modal .modal-dialog {
        width: 100%;
    }
}
