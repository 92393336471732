.asset-slider-module {
    @include std-bottom-margin(false);
    .prev, .next {
        margin: auto;
        background-image: url("../website/medias/icons/sliderarrow@2x.png");
        background-size: contain;
        cursor: pointer;
        width: 19px;
        height: 33px;
        &:focus {
            outline: none;
        }
    }
    .prev {
        margin-left: 0;
    }
    .next {
        margin-right: 0;
        transform: scale(-1);
    }
    .icon-frame {
        border: 1px solid $color-grenke-grey-warm-icon;
        height: 100%;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        &:before {
            content: "";
            position: absolute;
            margin: auto;
            top: 100%;
            left: 0;
            right: 0;
            width: 10px;
            height: 10px;
            margin-top: -4px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            border-right: 1px solid $color-grenke-grey-warm-icon;
            border-bottom: 1px solid $color-grenke-grey-warm-icon;
        }
        &:after {
            content: "";
            position: absolute;
            margin: auto;
            top: 100%;
            left: 0;
            right: 0;
            width: 12px;
            height: 1px;
            border-top: 1px solid $color-grenke-white-light;
        }
        @include media-breakpoint-up(lg) {
            left: auto;
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
        }
    }
    .asset-icon-slide {
        padding: 20px 15px;
        flex-direction: column;
        transition-timing-function: linear;
        width: auto;
        @include media-breakpoint-up(lg) {
            width: 100%;
        }
        &:not(fake) {
            cursor: pointer;
        }
        .icon-wrapper {
            width: 60px;
            height: 62px;
            margin: 0 auto 15px;
            .img-active {
                opacity: 0;
            }
        }
        .icon-description {
            margin: auto;
            .copy-text {
                text-align: center;
            }
        }
    }
    .swiper-slide-active .icon-wrapper .img-active, .swiper-slide-duplicate-active .icon-wrapper .img-active {
        @include transition(all, .5s);
        opacity: 1;
    }
    .asset-icon-container {
        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: 100%;
        }
        .asset-icon-slide {
            background-color: red;
            overflow: hidden;
            .icon-wrapper {
                height: 120px;
            }
        }
    }
    .asset-text-slider {
        margin-top: 50px;
        .asset-text-slide {
            background-color: $color-grenke-white-light;
            opacity: 0 !important;
            height: auto;
            &.swiper-slide-active {
                opacity: 1 !important;
            }
        }
    }
    .asset-indicator {
        margin-top: 30px;
        border-top: 1px solid $color-grenke-grey-light;
        padding-top: 30px;
        & span:nth-child(2) {
            margin: 0 5px;
        }
        @include media-breakpoint-up(sm) {
            margin-top: 40px;
            padding-top: 40px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: 50px;
            padding-top: 50px;
        }
    }
    .buttom-hover-margin {
        margin-right: 10px;
    }
    .asset-slider-padding {
        @include media-breakpoint-only(xs) {
            padding: 0;
        }
        @include media-breakpoint-only(sm) {
            padding: 0;
        }
        @include media-breakpoint-only(md) {
            padding: 0;
        }
    }
     div.icon-wrapper.img-fill-asset-slider > img{
         height: 100%;
         width: 100%;
         object-fit: cover;
     }
}
