.leasingtest-module {
  .leasingtest-inner-wrapper {
    background-color: $color-grenke-blue;
    position: relative;
    margin-top: 120px;
    @include std-bottom-margin(false);
    @include media-breakpoint-up(md) {
      margin-top: 0px;
    }
  }
  .background-triangle-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  .background-triangle {
    transform: rotate(180deg);
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent transparent $color-grenke-explorer-sky transparent;
    position: absolute;
    top: 0;
    border-width: 0 115px 100px 48px;
    right: -52px;
    @include media-breakpoint-up(md) {
      transform: none;
      bottom: 0;
      top: auto;
      border-width: 0 146px 188px 153px;
    }
    @include media-breakpoint-up(lg) {
      border-width: 0 132px 234px 272px;
    }
    @include media-breakpoint-up(xl) {
      border-width: 0 180px 310px 288px;
    }
  }
  .lens {
    background-image: url(../website/medias/icons/Lupe.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 195px;
    height: 195px;
    transform: rotate(270deg);
    right: -18px;
    top: -70px;
    @include media-breakpoint-up(md) {
      height: 270px;
      width: 270px;
      right: 32px;
      transform: none;
      top: auto;
      bottom: -14%;
    }
    @include media-breakpoint-up(lg) {
      height: 338px;
      width: 338px;
      right: 38px;
    }
    @include media-breakpoint-up(xl) {
      width: 422px;
      height: 422px;
      right: 48px;
    }
  }
  .lens-text-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .lens-text-inner-wrapper {
    position: absolute;
    transform: rotate(90deg);
    left: 78px;
    top: 49px;
    width: 96px;
    @include media-breakpoint-up(md) {
      transform: none;
      left: 114px;
      top: 72px;
      width: 126px;
    }
    @include media-breakpoint-up(lg) {
      left: 136px;
      top: 96px;
      width: 170px;
    }
    @include media-breakpoint-up(xl) {
      left: 178px;
      top: 118px;
      width: 200px;
    }
    &.intro-big {
      @include media-breakpoint-down(sm) {
        top: 49px;
      }
    }
    &.intro-small {
      @include media-breakpoint-down(sm) {
        top: 58px;
      }
    }
    &.small-text {
      left: 80px;
      top: 55px;
      @include media-breakpoint-up(md) {
        left: 112px;
        top: 76px;
      }
      @include media-breakpoint-up(lg) {
        left: 136px;
        top: 96px;
      }
      @include media-breakpoint-up(xl) {
        left: 176px;
        top: 124px;
      }
    }
  }
  .lens-text {
    color: $color-grenke-green;
    text-align: center;
    font-family: "Helvetica-regular";
    font-size: 13px;
    line-height: 18px;
    font-weight: bold;
    text-transform: uppercase;
    @include media-breakpoint-up(md) {
      font-size: 17px;
      line-height: 19px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 24px;
      line-height: 26px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 28px;
      line-height: 28px;
    }
    .lens-intro {
      display: block;
    }
    .lens-question-text {
      display: none;
    }
    .lens-number {
      display: none;
      text-align: center;
    }
    .lens-result {
      display: none;
    }
    span + span {
      margin: 0;
      @include media-breakpoint-up(md) {
        margin-top: 5px;
      }
    }
  }
  .leasingtest-outer {
    @include media-breakpoint-between(sm, md) {
      padding-left: 0;
    }
  }
  .leasingtest-slide-inner {
    @include media-breakpoint-up(sm) {
      padding-left: 0;
    }
  }
  .leasingtest-container {
    background-color: transparent;
    padding: 30px 15px;
    @include media-breakpoint-up(sm) {
      padding: 30px 15px 30px 0;
    }
    @include media-breakpoint-up(md) {
      padding: 40px 40px 40px 0;
    }
    @include media-breakpoint-up(xl) {
      padding: 80px 0px 100px;
    }

    .intro {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $white;
      }
      h1 + p,
      h2 + p,
      h3 + p,
      h4 + p,
      h5 + p,
      h6 + p {
        margin-top: 14px;
      }
      p {
        font-family: "Helvetica";
        font-size: 14px;
        line-height: 23px;
        color: $white;
        @include media-breakpoint-up(sm) {
          font-size: 15px;
          line-height: 26px;
        }
        @include media-breakpoint-up(xl) {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }

    .intro-button {
      margin-top: 32px;
      .btn-col {
        @include media-breakpoint-up(sm) {
          padding-left: 0;
        }
      }
    }

    .question-buttons {
      display: none;
      margin-top: 32px;
      .button {
        margin-bottom: 15px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 0;
        }
      }
      .btn-col + .btn-col {
        margin-top: 14px;
        @include media-breakpoint-up(xl) {
          margin-top: 0;
        }
      }
    }

    .question-box {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $white;
      }
    }

    .answer-buttons {
      display: none;
      margin-top: 32px;
      .btn-box {
        width: 100%;
      }
      .answer-button {
        display: none;
      }
      .btn-col + .btn-col {
        margin-top: 14px;
        @include media-breakpoint-up(xl) {
          margin-top: 0;
        }
      }
    }
  }

  .question-slider {
    .count-box {
      font-size: 13px;
      line-height: 18px;
      color: $white;
      text-align: center;
      margin-bottom: 30px;
      display: none;
    }

    p {
      @include h3();
      & {
        color: $white;
        text-align: left;
        padding: 0;
      }
    }

    .question-slide-item {
      min-height: 1px;
      &.result-box {
        h2 {
          text-align: left;
          color: $white;
        }
        h3 {
          color: $white;
          text-align: left;
          padding: 0;
        }
      }
    }
    .result-item {
      display: none;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $white !important;
        text-align: left !important;
      }
      h1 + p,
      h2 + p,
      h3 + p,
      h4 + p,
      h5 + p,
      h6 + p {
        margin-top: 14px;
      }
      p + h1,
      p + h2,
      p + h3,
      p + h4,
      p + h5,
      p + h6 {
        margin-top: 8px;
      }
      p {
        font-family: "Helvetica";
        font-size: 14px;
        line-height: 23px;
        color: $white;
        @include media-breakpoint-up(sm) {
          font-size: 15px;
          line-height: 26px;
        }
        @include media-breakpoint-up(xl) {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
  .btn-secondary:hover,
  .btn-secondary:focus {
    color: $white !important;
    background-color: $color-grenke-blue !important;
    .no-hover-state {
      opacity: 1 !important;
    }
    .hover-state {
      opacity: 0 !important;
    }
  }
  .no-padding {
    padding: 0;
  }
  .min-height-adaptive {
    min-height: 0px;
    @include media-breakpoint-up(md) {
      min-height: 330px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 400px;
    }
  }
}
