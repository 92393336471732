// COLORS

// Primary Colors
$color-grenke-grey-background: #faf9f9;
$color-grenke-grey-warm: #f1efec;
$color-grenke-grey-warm-60: #f5f2f1;
$color-grenke-blue: #40637e;
$color-grenke-grey-dark: #74726b;
$color-grenke-grey-dark-70: #9d9c97;
$color-grenke-white: #ffffff;
$color-grenke-white-light: #fcfcfb;
$color-grenke-white-80: rgba(255,255,255,.8);

// Specialties
$color-grenke-black-10: rgba(0,0,0,0.10);
$color-grenke-grey-footer: #eeece8;
$color-grenke-grey-light: #d2d2d2;
$color-grenke-grey-dark-warm: #d5d2c7;
$color-grenke-grey-warm-icon: #c8c2b5;
$color-grenke-green: #85bd49;
$color-grenke-buy: #605F5F;
$color-grenke-date-gray: #97948D;
$color-grenke-red: #B41C1C;
$color-grenke-error: #B41C1C;
$color-grenke-error-on-blue: #C6857E;
$color-grenke-blue-white-10: #53728b;
$color-grenke-country-switch-border: #d7d7d7;

// Explorer colours
$color-grenke-explorer-sky: #578696;
$color-grenke-explorer-sky-light: #81a1ad;
$color-grenke-explorer-aqua: #69979a;
$color-grenke-explorer-aqua-light: #90b0b0;
$color-grenke-explorer-mint: #699480;
$color-grenke-explorer-mint-light: #90ae9e;
$color-grenke-explorer-olive: #789764;
$color-grenke-explorer-olive-light: #9ab188;
$color-grenke-explorer-lime: #a6ab62;
$color-grenke-explorer-lime-light: #bec088;
$color-grenke-explorer-curry: #c59956;
$color-grenke-explorer-curry-light: #d4b37c;
$color-grenke-explorer-orange: #d48556;
$color-grenke-explorer-orange-light: #e1a67c;
$color-grenke-explorer-rouge: #b15e5b;
$color-grenke-explorer-rouge-light: #c6857e;

// Glossary colors
$color-grenke-grossary-border: #e6e5e5;

$bottom-gap-headline-module-xl: 50px;
$bottom-gap-headline-module-lg: 40px;
$bottom-gap-headline-module-md: 30px;
$bottom-gap-headline-module-sm: 30px;
$bottom-gap-headline-module-xs: 20px;

$bottom-gap-module-xl: 130px;
$bottom-gap-module-lg: 100px;
$bottom-gap-module-md: 80px;
$bottom-gap-module-sm: 80px;
$bottom-gap-module-xs: 50px;

$top-gap-modul-reduce-xl: #{(-($bottom-gap-module-xl) + 80px)};
$top-gap-modul-reduce-lg: #{(-($bottom-gap-module-lg) + 60px)};
$top-gap-modul-reduce-md: #{(-($bottom-gap-module-md) + 50px)};
$top-gap-modul-reduce-sm: #{(-($bottom-gap-module-sm) + 50px)};
$top-gap-modul-reduce-xs: #{(-($bottom-gap-module-xs) + 50px)};

// z-index
// 1040, to be over all modules but under modals
$z-index-header: 1040;

$color-grenke-grey-background-body: #fcfcfb;
