@import "~jquery-ui/themes/base/all.css";
@import "~jquery-ui/themes/base/datepicker.css";
.eloqua-form {
  @include std-bottom-margin(false);

  h3 + .formRow {
    margin-top: 20px;
  }

  h4 + .formRow {
    margin-top: 20px;
  }

  .formRow--col + .formRow--col {
    margin-top: 20px !important;
    @include media-breakpoint-up(sm) {
      margin-top: 30px !important;
    }
  }

  .formRow--item {
    position: relative;
  }

  .phone-time-col-3,
  .phone-time-col-4 {
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  .phone-time-col-5 {
    margin-top: 20px;
  }

  .callback-times {
    padding-top: 30px;
    .phone-time-error {
      position: relative;
      height: 40px;
      @include media-breakpoint-up(md) {
        height: 30px;
      }
      @include media-breakpoint-only(xs) {
        margin-top: 10px;
      }
      .error {
        @include formfield-error;
        position: absolute;
        bottom: -5px;
        left: 17px;
      }
    }
  }
  .upload {
    &-row {
      position: relative;
      & + .error-wrapper {
        @include formfield-error;
        position: relative;
        bottom: -5px;
        left: 17px;
        label.error.valid {
          display: none !important;
        }
      }
      .upload-error {
        display: none;
        &.not-valid {
          display: inline-block;
          @include media-breakpoint-up(sm) {
            min-width: 400px;
          }
        }
      }

      label.error,
      .file-type,
      .file-size {
        position: relative;
        @include formfield-error;
        color: $color-grenke-red;
        bottom: -3px;
        left: 17px;
      }

      .file-meta,
      .upload-progress-bar,
      .upload-remove {
        display: none;
      }
      .file {
        &-meta {
          display: none;
          &[data-fileType="application/pdf"] {
            .file-icon {
              background-image: url(../website/medias/icons/filetypes/pdf.png);
              background-size: cover;
            }
          }
          &[data-fileType="image/jpeg"] {
            .file-icon {
              background-image: url(../website/medias/icons/filetypes/icon_file_image@2x.png);
              background-size: contain;
            }
          }
        }
        &-name {
          @include copy-3;
          & {
            color: $color-grenke-grey-dark;
          }
        }
        &-icon {
          width: 19px;
          height: 30px;
          background-position: center;
          background-repeat: no-repeat;
          display: inline-block;
          background-size: contain;
          margin-right: 20px;
        }
      }
      .upload-progress-bar {
        .progress-bar {
          &-wrapper {
            position: relative;
            width: 100%;
          }
          &-default {
            background-color: $color-grenke-grey-warm;
            height: 20px;
            width: 100%;
            position: relative;
            .progress-bar-fill {
              position: absolute;
              top: 0;
              left: 0;
              height: 20px;
              width: 0%;
              background-color: $color-grenke-green;
              font-size: 10px;
              color: $color-grenke-white;
              line-height: 20px;
            }
          }
        }
      }
      .upload-remove {
        margin-top: 20px;
        @include media-breakpoint-up(sm) {
          margin-top: 30px;
        }
      }
    }
  }

  .tooltip-icon {
    background-image: url("../website/medias/icons/info_icon_grey.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 20px;
    width: 20px;
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 11px;
    &:hover {
      background-image: url("../website/medias/icons/info_icon_popup.png");
    }

    &.dropdown-tooltip {
      right: 50px;
    }

    &.upload-tooltip {
      top: 14px;
      right: -30px;
    }
  }

  .collapse + .tooltip-icon,
  .collapsing + .tooltip-icon {
    display: none;
  }

  .collapse.show + .tooltip-icon {
    display: block;
  }

  .collapse {
    flex-wrap: wrap;
    display: flex !important;
    max-height: 1000px;
    transition-property: max-height, margin, padding, border-width;
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;

    &:not(.show) {
      min-height: 0px;
      max-height: 0px;
      overflow: hidden;
      margin: 0 !important;
      padding: 0;
      border-width: 0 !important;

      fieldset {
        transition: opacity 200ms ease-in-out;
        opacity: 0;
      }
    }
  }
  div.col-10.col-sm-6.col-lg-6.d-flex.flex-wrap.formRow--col.person {
    display: none !important;
  }
}

.ui-datepicker {
  .ui-widget-header,
  .ui-widget-content,
  .ui-state-default,
  .ui-datepicker-calendar th {
    color: $color-grenke-blue;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    span {
      background-image: url("../website/medias/icons/arrow_blue.svg");
      background-position: 0;
      width: 10px;
    }
  }

  .ui-datepicker-prev {
    span {
      transform: rotate(180deg);
    }
  }

  .ui-datepicker-next {
    span {
      margin-left: -2px;
    }
  }

  .ui-state-highlight {
    color: $color-grenke-white;
    background: $color-grenke-blue;
    border-color: $color-grenke-blue;
  }

  .ui-state-active {
    color: $color-grenke-white;
    background: $color-grenke-green;
    border-color: $color-grenke-green;
  }
}
