.contact-teaser-module {
    @include std-bottom-margin(false);
    .heading-text {
        padding: 50px 20px 50px 50px;

        &:after {
            content:"";
            position: absolute;
            right: -30px;
            top: 50%;
            width: 0;
            height: 0;
            border-top: 17px solid transparent;
            border-bottom: 17px solid transparent;
            border-left: 17px solid $color-grenke-grey-warm;
            margin-top: -17px;
            z-index: 1;
        }
    }

    .search-form {
        background-color: $color-grenke-blue;
        color: #fff;
        height: 100%;
        width: calc(100% + 1px);
        position: relative;
        .wrapper {
            padding: 30px 80px;
            &.blue-theme {
            }
        }
    }
    .mla-teaser & {
        .phone-image {
            background-image: url(../website/medias/icons/hand_xs.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            bottom: 24px;
            left: 0;
            width: 88px;
            height: 187px;
            @include media-breakpoint-up(sm) {
                background-image: url(../website/medias/icons/hand.png);
                display: block;
                width: 130px;
                height: 157px;
                right: 10px;
                left: auto;
                bottom: 0;
            }
            @include media-breakpoint-up(md) {
                width: 183px;
                height: 221px;
                right: 14px;
            }
            @include media-breakpoint-up(lg) {
                width: 307px;
                height: 354px;
                right: 22px;
            }
            @include media-breakpoint-up(xl) {
                height: 436px;
                width: 354px;
                right: 44px;
            }
        }
        .vertical-line {
            &:before {
                background-image: url(../website/medias/icons/Strich.png);
                background-repeat: repeat-y;
                background-position: center;
                background-size: contain;
                content: "";
                position: absolute;
                top: 0;
                width: 15px;
                left: 6%;

                height: 34px !important;
                @include media-breakpoint-up(sm) {
                    height: 100% !important;
                    left: 5%;
                }
                @include media-breakpoint-up(md) {
                    left: 6%;
                }
                @include media-breakpoint-up(lg) {
                    left: 6.5%;
                }
            }
        }
        .teaser-headline {
            margin-bottom: 10px;
        }
        .background-box {
            background-color: $color-grenke-blue !important;
            padding: 40px 0;
        }
        h3 {
            color: $white;
            font-size: 22px;
            line-height: 30px;
            @include media-breakpoint-up(sm) {
                font-size: 32px;
                line-height: 44px;
            }
        }
        .search-form {
            background-color: transparent;
            p {
                font-size: 14px;
                @include media-breakpoint-up(sm) {
                    font-size: 16px;
                }
            }
        }
        .formRow--row + .formRow--row {
            margin-top: 40px;
        }
        .vertical-line {
            &:before {
                background-image: url(../medias/icons/line.png);
                background-repeat: repeat-y;
                background-position: center;
                background-size: contain;
                content: "";
                position: absolute;
                top: 0;
                z-index: 2;
                width: 15px;
                left: 6%;

                height: 34px !important;
                @include media-breakpoint-up(sm) {
                    height: 100% !important;
                    left: 5%;
                }
                @include media-breakpoint-up(md) {
                    left: 6%;
                }
                @include media-breakpoint-up(lg) {
                    left: 6.5%;
                }
            }
        }
    }
}
// formfield override
#contact-teaser {
    .formRow {
        label.error, span.error {
            bottom: auto;
            top: 40px;
        }
        &--input.error {
            margin-bottom: 20px;
        }
    }
}
