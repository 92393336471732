.storytelling {
  @include std-bottom-margin(false);
  .story-wrapper-right {
    min-height: 100%;
  }
  .story-image-wrapper {
    padding-bottom: 30px;
    margin-right: 60px;
    margin-left: 60px;
    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-left: 0;
    }
    @include media-breakpoint-up(sm) {
      padding-bottom: 40px;
    }
    @include media-breakpoint-up(md) {
      padding-bottom: 50px;
    }
    .story-image-container-height {
      height: 800px;
      @include media-breakpoint-up(sm) {
        height: 310px;
      }
      @include media-breakpoint-up(md) {
        height: 410px;
      }
      @include media-breakpoint-up(lg) {
        height: 550px;
      }
      @include media-breakpoint-up(xl) {
        height: 720px;
      }
      &.extra-quote-height {
        @include media-breakpoint-only(xs) {
          height: 810px;
        }
        @include media-breakpoint-only(sm) {
          height: 320px;
        }
      }
      &.extra-small-quote-height {
        @include media-breakpoint-only(xs) {
          height: 820px;
        }
        @include media-breakpoint-only(sm) {
          height: 330px;
        }
      }
    }
    .story-image-set {
      img,
      .story-quote-wrapper-right p {
        width: auto;
        transform: scale(1);
        &.story-animate-delay {
          transition: all 1s 0.5s;
          transform: scale(1);
        }
        &.story-animate-transition-delay {
          transition: all 0.5s;
        }
        &.story-animation {
          transform: scale(0);
        }
      }
      img {
        height: 100%;
      }
      &.single {
        img,
        .story-quote-wrapper-right p {
          transform: scale(1);
          transition: none;
        }
      }
      .story-image-wrapper-margin {
        @include media-breakpoint-up(sm) {
          margin-top: 30px;
        }
      }
      .story-image-wrapper-right {
        height: 240px;
        @include media-breakpoint-up(sm) {
          height: 140px;
        }
        @include media-breakpoint-up(md) {
          height: 190px;
        }
        @include media-breakpoint-up(lg) {
          height: 260px;
        }
        @include media-breakpoint-up(xl) {
          height: 345px;
        }
        @include media-breakpoint-only(xs) {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
      .story-image-wrapper-left {
        height: 255px;
        @include media-breakpoint-up(sm) {
          height: 255px;
        }
        @include media-breakpoint-up(md) {
          height: 280px;
        }
        @include media-breakpoint-up(lg) {
          height: 280px;
        }
        @include media-breakpoint-up(xl) {
          height: 505px;
        }
        @include media-breakpoint-only(xs) {
          margin-bottom: 15px;
        }
      }
      .story-quote-wrapper-right {
        height: 240px;
        display: flex;
        align-items: flex-end;
        @include media-breakpoint-up(sm) {
          height: 150px;
        }
        @include media-breakpoint-up(md) {
          height: 190px;
        }
        @include media-breakpoint-up(lg) {
          height: 260px;
        }
        @include media-breakpoint-up(xl) {
          height: 345px;
        }
        &.extra-quote-height {
          @include media-breakpoint-only(xs) {
            height: 250px;
          }
        }
        &.extra-small-quote-height {
          @include media-breakpoint-only(xs) {
            height: 260px;
          }
          @include media-breakpoint-only(sm) {
            height: 170px;
          }
        }
      }
      &.position-absolute {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }
  .story-content-slide {
    .story-content-copy-margin {
      margin-top: 15px;
    }
    .story-content-button-margin {
      margin-top: 30px;
    }

    .swiper-wrapper {
      .swiper-slide {
        @include media-breakpoint-down(sm) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    .copy-text.copy {
      @include media-breakpoint-up(md) {
        column-count: 2;
        column-gap: 30px;
      }
    }
  }
  .storytelling-videoplayer-wrapper {
    display: none;
    &.single,
    &.active {
      display: block;
    }
  }

  .story-arrow {
    margin: auto;
    background: transparent url("../website/medias/icons/sliderarrow@2x.png") center center / contain no-repeat;
    cursor: pointer;
    z-index: 10;
    width: 34px;
    height: 34px;
    &:focus {
      outline: none;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .story-arrow.prev {
    margin-left: 0;
  }

  .story-arrow.next {
    margin-right: 0;
    transform: rotate(180deg);
  }

  .story-arrow-left-wrapper {
    top: 0;
    left: 0;
    @include media-breakpoint-down(sm) {
      display: none!important;
    }
  }
  .story-arrow-right-wrapper {
    top: 0;
    right: 0;
    @include media-breakpoint-down(sm) {
      display: none!important;
    }
  }
  .story-arrow-wrapper {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .storytelling-swiper-pagination {
    margin-top: 16px;
    margin-bottom: -30px;
    width: 100%;
    text-align: center;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .storytelling-swiper-pagination .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: $color-grenke-blue;
    opacity: 1;
    margin: 0 5px;
    border: 1px solid $color-grenke-white;
  }

  .storytelling-swiper-pagination .swiper-pagination-bullet-active {
    transform: scale(1.5);
    background-color: $color-grenke-blue;
    border: 1px solid $color-grenke-white;
  }
}
