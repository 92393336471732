.scroll-info {
  @include media-breakpoint-up(sm) {
    display: none;
    .twenty &,
    .twentyfive & {
      display: block;
    }
  }
  @include media-breakpoint-up(md) {
    display: none;
    .twentyfive & {
      display: none;
    }
  }

  .scroll-wrapper {
    text-align: right;
    padding-right: 30px;
    position: relative;
    margin: 15px 0;
    z-index: 999;
    visibility: visible;
    &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 9px;
      top: 3px;
      right: 0;
      background: url("../website/medias/icons/arrows_comparisonchart.png") no-repeat center;
      background-size: contain;
    }
    &.hidden {
      visibility: hidden;
    }
  }
}

.comparison-chart-module {
  // define container-width for ie fix
  $container-width-xl: 1280px;
  $container-width-lg: 930px;
  $container-width-md: 690px;
  position: relative;
  overflow: auto;
  width: 100%;
  .d-table {
    width: 100%;
    @include media-breakpoint-only(md) {
      table-layout: auto;
      width: 150%;
    }
  }
  &.twenty {
    width: 150%;
    overflow: scroll;
    @include media-breakpoint-up(lg) {
      overflow: hidden;
    }
    .scroll-gradient {
      @include media-breakpoint-up(sm) {
        display: block;
        width: 50px;
      }
      @include media-breakpoint-up(md) {
        display: block;
        width: 110px;
      }
    }
  }
  &.twentyfive {
    width: 100%;
    overflow: scroll;
    @include media-breakpoint-up(lg) {
      overflow: hidden;
    }
    .scroll-gradient {
      @include media-breakpoint-up(sm) {
        display: block;
        width: 50px;
      }
      @include media-breakpoint-up(md) {
        display: none;
        width: 110px;
      }
    }
  }
  .d-table-row {
    background-color: transparent;
    .scrolling-container {
      width: 43vw;
      @include media-breakpoint-up(sm) {
        width: 100%;
      }
    }
    &.twentyfive {
      .d-table-cell {
        width: 25%;
        padding: 0 15px;
        @include media-breakpoint-up(lg) {
          padding: 0 20px;
        }
        @include media-breakpoint-up(xl) {
          padding: 0 40px;
        }
        .scrolling-container {
          width: 43vw;
          @include media-breakpoint-up(sm) {
            width: 33vw;
          }
          @include media-breakpoint-up(md) {
            width: 100%;
            max-width: calc($container-width-md/4);
          }
          @include media-breakpoint-only(lg) {
            max-width: calc($container-width-lg/4);
          }
          @include media-breakpoint-only(xl) {
            max-width: calc($container-width-xl/4);
          }
        }
      }
    }
    &.twenty {
      &:first-child,
      &:last-child {
        .d-table-cell {
          padding: 0 15px;
          @include media-breakpoint-up(lg) {
            padding: 0 20px;
          }
        }
      }
      .d-table-cell {
        width: 20%;
        padding: 0 15px;
        @include media-breakpoint-only(md) {
          width: 30%;
        }
        @include media-breakpoint-up(lg) {
          padding: 0 20px;
        }
        @include media-breakpoint-up(xl) {
          padding: 0 30px;
        }
        .scrolling-container {
          width: 43vw;
          @include media-breakpoint-up(sm) {
            width: 33vw;
          }
          @include media-breakpoint-up(md) {
            width: 16vw;
          }
          @include media-breakpoint-only(md) {
            width: 23vw;
          }
          @include media-breakpoint-up(lg) {
            width: 100%;
            max-width: calc($container-width-lg/5);
          }
          @include media-breakpoint-only(xl) {
            max-width: calc($container-width-xl/5);
          }
        }
      }
    }
  }
  .body-row {
    height: 105px;
    min-height: 105px;
    &:nth-child(odd) {
      background-color: transparent;
    }
    &:first-child {
      .d-table-cell {
        border-bottom: none;
        vertical-align: top;
      }
    }
    &:last-child {
      .d-table-cell {
        vertical-align: top;
      }
    }
    .d-table-cell {
      border-bottom: 1px solid $color-grenke-grey-light;
      border-left: 1px solid $color-grenke-grey-light;
      width: calc(100% / 3);
      padding: 0 15px;
      vertical-align: middle;
      @include media-breakpoint-up(md) {
        padding: 0 20px;
      }
      @include media-breakpoint-up(lg) {
        padding: 0 30px;
      }
      @include media-breakpoint-up(xl) {
        padding: 0 40px;
      }
      &:first-child {
        padding: 0 15px 0 0;
        border-left: none;
        @include media-breakpoint-up(md) {
          padding: 0 20px 0 0;
        }
        @include media-breakpoint-up(lg) {
          padding: 0 30px 0 0;
        }
        @include media-breakpoint-up(xl) {
          padding: 0 40px 0 0;
        }
      }
      .compare-icon {
        text-align: center;
        width: 30px;
        height: 30px;
        background: url("../website/medias/icons/check_green.png") no-repeat center;
        background-size: contain;
        margin: 0 auto;
      }
      &.highlighted {
        background: $color-grenke-grey-warm;
        border-left: none;
        + .d-table-cell {
          border-left: none;
        }
      }
      .button-box {
        margin: 20px 0 30px;
      }
    }
    &:last-child {
      .d-table-cell {
        border-bottom: none;
      }
    }
  }
  .scroll-gradient {
    width: 50px;
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    //transition: width .5s;
    background: rgba(255, 255, 255, 0);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(250, 249, 249, 0.88) 100%);
    z-index: 99;
    display: block;
    @include media-breakpoint-up(sm) {
      display: none;
    }
    &.hidden {
      display: none;
      //transition: width 0s;
    }
  }
}
