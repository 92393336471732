// styles for fr_FR

#contact-detail-page {
    #contact-detail-form[data-language="fr_FR"] {
        #requestInterest-col {
            [data-dropdown-value="Consulting"],
            [data-dropdown-value="Offers"],
            [data-dropdown-value="Customer_Service"],
            [data-dropdown-value="Career"],
            [data-dropdown-value="Press"],
            [data-dropdown-value="Other"],
            [data-dropdown-value="Cooperation"] {
                display: block;
            }
        }
        // show topic-dropdown
        &[data-form-interest="Consulting"],
        &[data-form-interest="Offers"],
        &[data-form-interest="Customer_Service"],
        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                display: block;
            }
        }
        &[data-form-interest="Consulting"] {
            #requestTopic-col {
                [data-dropdown-value="Liquidity_Entrepreneurs"],
                [data-dropdown-value="Usage_Solutions"],
                [data-dropdown-value="Asset_Financing"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Cooperation"] {
            #requestTopic-col {
                [data-dropdown-value="Dealer_Managed_Service"],
                [data-dropdown-value="Dealer_Sales_Support"] {
                    display: block;
                }
            }
        }

        &[data-form-interest="Offers"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing_And_Rental"] {
                    display: block;
                }
            }

            &[data-form-topic="Leasing_And_Rental"] {
                .upload-row {
                    display: flex;
                    [data-upload-title="supplier"], [data-upload-btn="supplier"] {
                        display: block;
                    }
                }
            }
        }

        &[data-form-interest="Customer_Service"] {
            #requestTopic-col {
                [data-dropdown-value="Leasing"] {
                    display: block;
                }
            }

            &[data-form-topic="Leasing"] {
                .row-customer-service {
                    display: flex;
                }
                [data-customer-service-item="CustomerNumber"],
                [data-customer-service-item="ContractNumber"] {
                    display: block;
                }
            }

        }

        .imprint-row {
            display: flex;
        }
        .phone-contact-number-grenke-leasing {
            display: block;
        }
        #imprint-grenke-leasing {
            display: inline;
        }

        #phone-time-wrapper-js {
            .time-slot {
                display: block;
                max-width: 100%;
                flex: 0 0 100%;
            }
            .time-slot+.time-slot{
                margin-top: 20px;
            }
        }

        //[data-form-input-name="PostalBox"],
        [data-form-input-name="CompanyRegistrationNo"],
        [data-form-input-name="City"] {
            display: block;
        }

        [data-form-input-name="PostalBox"] {
            display: none;
        }

        [data-form-input-name="PostalBox"],
        [data-form-input-name="City"],
        [data-form-input-name="CompanyRegistrationNo"] {
            margin-top: 20px;
            @include media-breakpoint-up(sm) {
                margin-top: 30px;
            }
        }
    }
}