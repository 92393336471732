.icons-container {
    padding: 0;
    &.custom-fluid-container {
        @include media-breakpoint-down(sm) {
            width: 100%;
            max-width: none;
            padding-right: 0px;
            padding-left: 0px;
            margin-right: 0;
            margin-left: 0;
        }
    }
    @include std-bottom-margin(false);
}

.icons-box,
.icon{
    //@include std-bottom-margin(false);
    //@include media-breakpoint-down(sm) {
    //    margin-bottom: 0px;
    //}
    padding: 0;
    .dot-bar {
        margin-top: 40px;
    }
}

.icon-module {
    padding: 15px 0;

    &.quad:nth-child(odd){
        border-right: 1px solid $color-grenke-grey-light;
    }
    &:nth-child(1){
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
    &:nth-child(2){
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-up(sm) {
        border-right: 1px solid $color-grenke-grey-light;
        &.quad:nth-child(2) {
            border-right: none;
        }
    }
    @include media-breakpoint-up(md) {
        &.quad:nth-child(2) {
            border-right: 1px solid $color-grenke-grey-light;
        }
    }
    &.icon-module-no-border {
        border: none;
    }

    .slick-slide & {
        margin: 0;
        @include media-breakpoint-up(sm) {
            border-right: 1px solid $color-grenke-grey-light;
        }
        img {
            display: inline-block;
        }
    }
}

.icon-slider {
    .slick-track {
        display: flex;
    }
    .slick-slide > div {
        height: 100%;
    }
}