.inpage-navigation {
  margin-bottom: 35px;
  @include media-breakpoint-only(xl) {
    margin-top: -50px;
  }
  @include media-breakpoint-only(lg) {
    margin-top: -50px;
  }
  @include media-breakpoint-down(md) {
    margin-top: -30px;
  }
  @include media-breakpoint-only(xs) {
    margin-top: -30px;
  }
  .inpage-navigation-slider {
    .inpage-navigation-items {
      .swiper-slide {
        @include media-breakpoint-up(sm) {
          &:first-child {
            .inpage-navigation-item {
              padding: 15px 25px 15px 30px;
            }
          }
          &:last-child {
            .inpage-navigation-item {
              padding: 15px 30px 15px 25px;
            }
          }
        }
        .inpage-navigation-item {
          padding: 15px 20px;
          width: 100%;
          position: relative;
          text-align: center;
          @include media-breakpoint-up(sm) {
            text-align: left;
          }
          @include media-breakpoint-between(sm, md) {
            padding: 15px 30px;
          }
          a {
            white-space: nowrap;
          }
          .inpage-navigation-item-arrow-wrapper {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            background-color: $color-grenke-white-light;

            .inpage-navigation-item-arrow-inner {
              width: 10px;
              height: 10px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              border-left: 1px solid $color-grenke-grey-light;
              border-top: 1px solid $color-grenke-grey-light;
            }
          }
        }
      }
    }
    .inpage-navigation-arrow-wrapper {
      top: 0;
      outline: none;
      z-index: 1;
      border-bottom: solid 1px $color-grenke-grey-light;
      min-width: 50px;
      @include media-breakpoint-up(sm) {
        min-width: 0;
      }
      &:hover,
      &:focus,
      &:visited {
        outline: none;
      }
      &-prev {
        left: 0;
        background: linear-gradient(
          to right,
          rgba(252, 252, 251, 1) 0%,
          rgba(252, 252, 251, 1) 10%,
          rgba(253, 253, 253, 0) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        cursor: pointer;
        img {
          position: relative;
          left: -15px;
        }
      }
      &-next {
        right: 0;
        background: linear-gradient(
          to right,
          rgba(253, 253, 253, 0) 0%,
          rgba(252, 252, 251, 1) 90%,
          rgba(252, 252, 251, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        cursor: pointer;
        img {
          transform: rotate(180deg);
          position: relative;
          right: -15px;
        }
      }
      .inpage-navigation-arrow {
        .inpage-navigation-arrow-image {
          background-repeat: no-repeat;
          background-size: contain;
          width: 22px;
          height: 22px;
        }
        .inpage-navigation-arrow-image.prev {
          background-image: url("../website/medias/icons/sliderarrow@2x.png");
        }
        .inpage-navigation-arrow-image.next {
          background-image: url("../website/medias/icons/sliderarrow@2x.png");
          rotate: 180deg;
        }
      }
    }
  }
  .inpage-navigation-border {
    border-bottom: solid 1px $color-grenke-grey-light;
    margin-top: -1px;
  }
}
